import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import GenderRadioGroup from './GenderRadioGroup';
import AgeSelect from './AgeSelect';
import EmailField from './EmailField';
import PasswordField from './PasswordField';
import { signup } from '../../services/api/auth/authApi';
import { checkEmailAvailability } from '../../utils/apiUtils';
import { validatePassword } from '../../utils/validationUtils';
import { useNavigate } from 'react-router-dom';
import AlertModal from '../../components/common/AlertModal'; // AlertModal 추가
import styles from './SignUp.module.css';

function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    gender: '남',
    ageGroup: '20대',
  });

  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [emailCheckMessage, setEmailCheckMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // 모달 상태 추가
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [onModalClose, setOnModalClose] = useState(() => () => {}); // 모달 닫기 동작

  // 입력 필드 변경 핸들러
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'email') {
      setIsEmailChecked(false);
      setEmailCheckMessage('');
    }

    if (name === 'password') {
      if (!validatePassword(value)) {
        setPasswordMessage(
          '비밀번호는 8~20자여야 하며, 대문자, 소문자, 숫자, 특수문자를 각각 최소 1개 포함해야 합니다.'
        );
        setIsPasswordValid(false);
      } else {
        setPasswordMessage('안전한 비밀번호입니다. 사용 가능합니다.');
        setIsPasswordValid(true);
      }
    }
  };

  // 이메일 중복 확인 핸들러
  const handleEmailCheck = async () => {
    try {
      const response = await checkEmailAvailability(formData.email);
      const { retCode, retMessage } = response;

      switch (retCode) {
        case '00': // 사용 가능한 이메일
          setEmailCheckMessage('사용 가능한 이메일입니다.');
          setIsEmailChecked(true);
          break;
        case '01': // 이메일 형식 오류
          setEmailCheckMessage('이메일 형식이 올바르지 않습니다. (example@example.com)');
          setIsEmailChecked(false);
          break;
        case '03': // 중복된 이메일
          setEmailCheckMessage('해당 이메일은 이미 사용 중입니다.');
          setIsEmailChecked(false);
          break;
        default: // 기타 오류
          setEmailCheckMessage(retMessage || '알 수 없는 오류가 발생하였습니다.');
          setIsEmailChecked(false);
      }
    } catch (error) {
      console.error('❌ Error in handleEmailCheck:', error);
      setEmailCheckMessage('서버 오류로 이메일 확인에 실패하였습니다.');
      setIsEmailChecked(false);
    }
  };

  // 회원가입 제출 핸들러
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isEmailChecked) {
      setModalMessage('이메일 중복 확인을 완료해주세요.');
      setOnModalClose(() => () => setShowModal(false)); // 모달 닫기만 수행
      setShowModal(true);
      return;
    }

    if (!isPasswordValid) {
      setModalMessage('비밀번호 형식이 올바르지 않습니다.');
      setOnModalClose(() => () => setShowModal(false)); // 모달 닫기만 수행
      setShowModal(true);
      return;
    }

    const requestData = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      genderType: formData.gender,
      age: formData.ageGroup,
    };

    try {
      const response = await signup(requestData);
      if (response.retCode === '00') {
        setModalMessage(`${formData.name}님, 회원가입을 축하드립니다!`);
        setOnModalClose(() => () => navigate('/login')); // 로그인 페이지로 이동
        setShowModal(true);
      } else {
        setModalMessage(`회원가입 실패: ${response.retMessage}`);
        setOnModalClose(() => () => setShowModal(false)); // 모달 닫기만 수행
        setShowModal(true);
      }
    } catch (error) {
      console.error('❌ Error in handleSubmit:', error);
      setModalMessage('서버 오류로 인해 회원가입에 실패했습니다.');
      setOnModalClose(() => () => setShowModal(false)); // 모달 닫기만 수행
      setShowModal(true);
    }
  };

  const isFormValid =
    formData.name &&
    formData.email &&
    isEmailChecked &&
    formData.password &&
    isPasswordValid;

  return (
    <div className={styles.fullPageContainer}>
      <div className={styles.formContainer}>
        <h2 className={styles.formTitle}>회원가입</h2>
        <Form onSubmit={handleSubmit} className={styles.formContent}>
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>이름</Form.Label>
            <Form.Control
              type="text"
              placeholder="이름을 입력하세요"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <EmailField
            value={formData.email}
            onChange={handleChange}
            onCheck={handleEmailCheck}
            message={emailCheckMessage}
            isValid={isEmailChecked}
          />

          <PasswordField
            value={formData.password}
            onChange={handleChange}
            message={passwordMessage}
            isValid={isPasswordValid}
          />

          <GenderRadioGroup
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          />

          <AgeSelect
            name="ageGroup"
            value={formData.ageGroup}
            onChange={handleChange}
          />

          <Button
            variant="primary"
            type="submit"
            className={styles.submitButton}
            disabled={!isFormValid}
          >
            가입하기
          </Button>
        </Form>
      </div>

      {/* AlertModal */}
      {showModal && (
        <AlertModal
          message={modalMessage}
          onClose={() => {
            setShowModal(false); // 모달 닫기
            onModalClose(); // 추가 동작 실행 (예: 로그인 페이지로 이동)
          }}
        />
      )}
    </div>
  );
}

export default SignUp;
