import React from 'react';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './CenterColumn.module.css';
import DaySelector from './DaySelector';

function CenterColumn({
  totalDays = 5,
  selectedDay,
  onDayChange,
  addedPlaces,
  onRemovePlace,
  onReorderPlaces,
  onAddPlace, // 추가된 prop
}) {
  const currentDayData = addedPlaces.find((dayData) => dayData.day === selectedDay);

  const handleRemovePlace = (placeId) => {
    onRemovePlace(selectedDay, placeId);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedPlaces = Array.from(currentDayData.places);
    const [removed] = reorderedPlaces.splice(result.source.index, 1);
    reorderedPlaces.splice(result.destination.index, 0, removed);

    onReorderPlaces(selectedDay, reorderedPlaces);
  };

  const renderPlaceItem = (place, index) => {
    if (!place || !place.name || !place.id) {
      console.error('Invalid place data:', place);
      return null;
    }

    const icons = {
      accommodations: '🏨',
      attractions: '📍',
      restaurants: '🍴',
    };

    const itemClass = styles[`${place.table}Item`] || styles.defaultItem;

    return (
      <Draggable key={place.id.toString()} draggableId={place.id.toString()} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`${styles.placeItem} ${itemClass}`}
          >
            <span className={styles.placeIcon}>{icons[place.table] || '📍'}</span>
            <span className={styles.placeName}>{place.name}</span>
            <Button
              variant="danger"
              size="sm"
              className={styles.removeButton}
              onClick={() => handleRemovePlace(place.id)}
            >
              삭제
            </Button>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div className={styles.centerColumn}>
      <DaySelector totalDays={totalDays} onDayChange={onDayChange} />
      <div className={styles.placesContainer}>
        {currentDayData ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="places">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {currentDayData.places.map((place, index) => renderPlaceItem(place, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <p>선택한 날짜에 데이터가 없습니다.</p>
        )}
      </div>
    </div>
  );
}

export default CenterColumn;
