import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './CourseMain.module.css';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import LeftColumn from './leftColumn/LeftColumn';
import CenterColumn from './centerColumn/CenterColumn';
import RightColumn from './rightColumn/RightColumn';
import { fetchRecommendedSpots, startChatbotSession, createCourseWithMultipart } from '../../../services/api/course/CourseCreateService';

function CourseMain() {
  const location = useLocation();
  const navigate = useNavigate();
  const { destination, duration } = location.state;

  const [showCourse, setShowCourse] = useState(false);
  const [addedPlaces, setAddedPlaces] = useState(
    Array.from({ length: duration }, (_, i) => ({ day: i + 1, places: [] }))
  );
  const [botLoadingMessage, setBotLoadingMessage] = useState("챗봇 응답을 기다리는 중입니다...");
  const [botResponses, setBotResponses] = useState([]); // 챗봇 응답 데이터 저장
  const [recommendedSpots, setRecommendedSpots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDay, setSelectedDay] = useState(1);

  const [sessionId, setSessionId] = useState(null); // 세션 ID
  const [initialBotMessage, setInitialBotMessage] = useState(''); // 첫 메시지
  const [showModal, setShowModal] = useState(false); // Modal 창 표시 상태
  const [courseTitle, setCourseTitle] = useState(''); // 사용자 입력 제목
  
  const handleAddBotResponseToPlanner = (recommendations, table = 'botResponse') => {
    if (!Array.isArray(recommendations)) {
      console.error('Invalid recommendations:', recommendations);
      return;
    }
  
    const parsedResponse = recommendations.map((item) => ({
      name: item.name || item.title,
      address: item.address,
      latitude: item.latitude,
      longitude: item.longitude,
      table: table, // 명시적으로 table 값 추가
      id: item.id || (Date.now() + Math.random()),
    }));
  
    // 첫 번째 요소에 전체 테이블 정보 포함
    const formattedBotResponses = [{
      day: 1,
      schedule: parsedResponse,
      table: table
    }];
  
    setBotResponses(formattedBotResponses);
    console.log('🟢 Updated botResponses:', formattedBotResponses);
  };
  
  
  
  
  
  

  const startChatbot = async () => {
    try {
      setBotLoadingMessage('챗봇 응답을 기다리는 중입니다...');
      const response = await startChatbotSession(destination, duration);
      if (response.success) {
        setSessionId(response.data.session_id);
        const botMessage = response.data.response.message;

        if (botMessage === 'JSON 파싱에 실패했습니다.') {
          setBotLoadingMessage('질문을 이해하지 못했습니다. 다시 시도해 주세요.');
        } else {
          setBotLoadingMessage('왼쪽에서 챗봇의 응답을 확인하세요.');
          handleAddBotResponseToPlanner(botMessage); // 챗봇 응답을 Planner에 추가
        }
      } else {
        setBotLoadingMessage('챗봇 세션을 시작하지 못했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('Error starting chatbot session:', error);
      setBotLoadingMessage('오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const loadRecommendedSpots = async () => {
    setLoading(true);
    const result = await fetchRecommendedSpots(destination);

    if (result.success) {
      setRecommendedSpots(result.data);
    } else {
      setError(result.error);
    }
    setLoading(false);
  };

  const handleAddPlaceToDay = (placeData) => {
    setAddedPlaces((prev) =>
      prev.map((dayData) =>
        dayData.day === selectedDay
          ? { ...dayData, places: [...dayData.places, ...(Array.isArray(placeData) ? placeData : [placeData])] }
          : dayData
      )
    );
  };
  

  const handleRemovePlace = (day, placeId) => {
    setAddedPlaces((prev) =>
      prev.map((dayData) =>
        dayData.day === day
          ? { ...dayData, places: dayData.places.filter((place) => place.id !== placeId) }
          : dayData
      )
    );
  };

  const handleReorderPlaces = (day, reorderedPlaces) => {
    setAddedPlaces((prev) =>
      prev.map((dayData) =>
        dayData.day === day
          ? { ...dayData, places: reorderedPlaces }
          : dayData
      )
    );
  };

  const handleSaveCourse = async () => {
    if (!courseTitle.trim()) {
      alert('코스 제목을 입력해주세요.');
      return;
    }
  
    const token = localStorage.getItem('authToken');
    const isPrivate = true;
  
    // 모든 장소를 기반으로 spotCreateRequest 생성
    const spotCreateRequest = addedPlaces.reduce(
      (acc, dayData) => {
        dayData.places.forEach((place, index) => {
          const spot = {
            title: place.name,
            latitude: parseFloat(place.latitude),
            longitude: parseFloat(place.longitude),
            dayNumber: dayData.day,
            orderNumber: index + 1,
          };
  
          // table 값에 따라 적절한 배열에 추가
          switch (place.table) {
            case 'accommodations':
              acc.accommodations.push(spot);
              break;
            case 'restaurants':
              acc.restaurants.push(spot);
              break;
            case 'attractions':
              acc.touristAttractions.push(spot);
              break;
          }
        });
        return acc;
      },
      { accommodations: [], restaurants: [], touristAttractions: [] }
    );
  
    const createRequest = {
      title: courseTitle,
      isPrivate,
      spotCreateRequest,
    };
  
    try {
      const response = await createCourseWithMultipart(token, createRequest);
  
      if (response.retCode === '00') {
        console.log('Course created successfully:', response.data);
  
        const courseId = response.data.courseId;
        navigate(`/courses/${courseId}`);
      } else {
        console.error('Failed to create course:', response.retMessage);
      }
    } catch (error) {
      console.error('Error creating course:', error);
    }
  };
  
  

  useEffect(() => {
    loadRecommendedSpots();
    startChatbot(); // 페이지 로드 시 챗봇 세션 시작
  }, [destination]);

  return (
    <>
      <CustomNavbar />
      <Container fluid className={styles.pageContainer}>
        <Row className={`${styles.fullHeightRow} align-items-stretch`}>
          <Col md={3} className={`${styles.column} ${styles.leftColumn}`}>
            <div className={styles.columnContent}>
            <LeftColumn
              destination={destination}
              duration={duration}
              botRecommendations={botResponses} // botResponses 전달 확인
              onAddPlace={handleAddPlaceToDay} // 챗봇 데이터도 추가 가능
            />


            </div>
          </Col>
          <Col md={6} className={`${styles.column} ${styles.centerColumn}`}>
            <div className={styles.columnContent}>
              <CenterColumn
                totalDays={duration}
                selectedDay={selectedDay}
                onDayChange={setSelectedDay}
                addedPlaces={addedPlaces}
                onRemovePlace={handleRemovePlace}
                onReorderPlaces={handleReorderPlaces}
              />
              <Button
                variant="primary"
                className={styles.confirmButton}
                disabled={addedPlaces.every((day) => day.places.length === 0)}
                onClick={() => setShowModal(true)} // Modal 열기
              >
                코스 저장
              </Button>
            </div>
          </Col>
          <Col md={3} className={`${styles.column} ${styles.rightColumn}`}>
            <div className={styles.columnContent}>
              <RightColumn
                onShowCourses={setShowCourse}
                destination={destination}
                duration={duration}
                sessionId={sessionId}
                initialBotMessage={initialBotMessage}
                onAddBotResponse={handleAddBotResponseToPlanner} // 전달
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>코스 제목 입력</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="courseTitle">
              <Form.Label>코스 제목</Form.Label>
              <Form.Control
                type="text"
                placeholder="코스 제목을 입력하세요"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            취소
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
              handleSaveCourse();
            }}
          >
            저장
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CourseMain;
