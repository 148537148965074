import axiosInstance from '../axiosInstance';

// 이미지를 처리하는 메서드 (Google 이미지와 일반 이미지 분리)
const processImage = async (imagePath) => {
  try {
    // Google 이미지는 Blob 변환 없이 URL 그대로 반환
    if (imagePath.startsWith('https://lh3.googleusercontent.com') || imagePath.startsWith('https://lh3.google.com')) {
      return imagePath;
    }

    // 일반 이미지는 Blob으로 변환
    const response = await axiosInstance.get(imagePath, {
      responseType: 'blob', // 이미지를 Blob으로 받음
    });
    return URL.createObjectURL(response.data); // Blob URL 생성
  } catch (error) {
    console.error(`❌ Failed to load image: ${imagePath}`, error);
    return 'https://via.placeholder.com/300'; // 기본 이미지 반환
  }
};

// 여행 코스 데이터를 가져오는 메서드
const getTravelCourses = async (page = 0, size = 12) => {
  try {
    const response = await axiosInstance.get('/api/v1/course', {
      params: { page, size },
    });

    if (response.data.retCode === '00') {
      const { data } = response.data.data;
      const totalItems = response.data.data.totalItems;

      // 이미지 로딩 처리 추가
      const coursesWithImages = await Promise.all(
        data.map(async (course) => {
          if (course.imageUrl) {
            const imageUrl = await processImage(course.imageUrl);
            return { ...course, imageUrl }; // Google 이미지 또는 Blob URL 추가
          }
          return { ...course, imageUrl: 'https://via.placeholder.com/300' }; // 기본 이미지
        })
      );

      return { data: coursesWithImages, totalItems };
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('❌ Error fetching travel courses:', error);
    throw error;
  }
};

export { getTravelCourses };

const getUserCourses = async (page = 0, size = 10) => {
  try {
    const response = await axiosInstance.get('/api/v1/course/user', {
      params: { page, size },
    });

    if (response.data.retCode === '00') {
      const { data } = response.data.data;
      const totalItems = response.data.data.totalItems;

      // 이미지 로딩 처리 추가
      const coursesWithImages = await Promise.all(
        data.map(async (course) => {
          if (course.imageUrl) {
            const imageUrl = await processImage(course.imageUrl);
            return { ...course, imageUrl };
          }
          return { ...course, imageUrl: 'https://via.placeholder.com/300' };
        })
      );

      return { data: coursesWithImages, totalItems };
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('❌ Error fetching user courses:', error);
    throw error;
  }
};

export { getUserCourses };

