import React, { useState } from 'react';
import { FaBed, FaUtensils, FaMapMarkerAlt } from 'react-icons/fa';
import { Button, Card, Dropdown } from 'react-bootstrap';
import './Planner.module.css';

function Planner({ 
  plannerData = [], 
  duration, 
  onSwitchToRecommended, 
  onAddPlace, 
  onRetry 
}) {
  const [selectedDay, setSelectedDay] = useState(1);

  const handleAddPlace = (place) => {
    const placeData = {
      name: place.name,
      address: place.address,
      longitude: place.longitude,
      latitude: place.latitude,
      table: place.table || 'unknown',
      id: place.id || Date.now(),
      description: place.description,
    };

    onAddPlace(placeData);
    console.log('추가될 항목', placeData);
  };

  const renderDays = () =>
    [...Array(duration).keys()].map((day) => (
      <Button
        key={day}
        onClick={() => setSelectedDay(day + 1)}
        className={`day-button ${selectedDay === day + 1 ? 'active' : ''}`}
      >
        Day {day + 1}
      </Button>
    ));

  const renderItemsByTable = (items, table, title, IconComponent) => {
    const filteredItems = items.filter((item) => item.table === table);

    if (!filteredItems.length) {
      return <p className="no-data-message">🛑 해당 데이터가 없습니다.</p>;
    }

    return (
      <>
        <h5 className="item-type-header">
          <IconComponent className="section-icon" /> {title}
        </h5>
        <div className="item-grid">
          {filteredItems.map((item) => (
            <Card key={item.id} className="plan-item">
              <Card.Body>
                <Dropdown>
                  <Dropdown.Toggle
                    as={Card.Title}
                    id={`dropdown-${item.id}`}
                    className="card-title"
                  >
                    {item.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleAddPlace(item)}
                      className="dropdown-add-button"
                    >
                      추가
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Card.Text>📍 {item.address || '정보 없음'}</Card.Text>
                <Card.Text>📖 {item.description || '설명이 없습니다.'}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </>
    );
  };

  const currentDayItems = plannerData.find((day) => day.day === selectedDay)?.schedule || [];

  return (
    <div className="planner">
      <Button onClick={onRetry} className="mb-3 btn-warning">
        🔄 일정 새로 만들기
      </Button>
      <div className="day-buttons">{renderDays()}</div>

      {renderItemsByTable(currentDayItems, 'accommodations', '숙소', FaBed)}
      {renderItemsByTable(currentDayItems, 'restaurants', '식당', FaUtensils)}
      {renderItemsByTable(currentDayItems, 'attractions', '관광지', FaMapMarkerAlt)}
    </div>
  );
}

export default Planner;
