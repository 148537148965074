import React from 'react';
import { Form } from 'react-bootstrap';

function AgeSelect({ name, value, onChange }) {
  return (
    <Form.Group className="mb-3" controlId="formAgeGroup">
      <Form.Label>나이</Form.Label>
      <Form.Select name={name} value={value} onChange={onChange} className="w-100">
        {['10대', '20대', '30대', '40대', '50대', '기타'].map((age) => (
          <option key={age} value={age}>{age}</option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}

export default AgeSelect;
