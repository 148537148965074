import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript, Polyline } from '@react-google-maps/api';
import styles from './MapView.module.css';

const containerStyle = {
  width: '1200px',
  height: '750px',
};

const initialCenter = {
  lat: 37.5665,
  lng: 126.978,
};

const dayColors = ['#cc00ff', '#0000FF', '#00FF00', '#FFA500', '#b700ff'];

const LIBRARIES = ['marker'];

function MapView({ spots = [], dayNumber = 1 }) {
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(initialCenter);
  const markersRef = useRef([]); // 마커 관리

  useEffect(() => {
    if (spots.length > 0) {
      setMapCenter({
        lat: spots[0].latitude,
        lng: spots[0].longitude,
      });
    }
  }, [spots]);

  const strokeColor = dayColors[(dayNumber - 1) % dayColors.length];

  useEffect(() => {
    if (map && window.google) {
      // 기존 마커 제거
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = []; // 기존 마커 배열 초기화

      // 새로운 마커 추가
      spots.forEach((spot, index) => {
        const marker = new window.google.maps.Marker({
          position: { lat: spot.latitude, lng: spot.longitude },
          map: map,
          title: spot.title,
          label: {
            text: `${index + 1}`,
            color: 'white',
            fontSize: '12px',
          },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: strokeColor, // 날짜별 색상 적용
            fillOpacity: 1,
            strokeColor: 'white',
            strokeWeight: 3,
            scale: 17
          },
        });
        markersRef.current.push(marker); // 마커 참조 저장
      });
    }
  }, [map, spots, strokeColor, dayNumber]);

  return (
    <div className={styles.mapViewContainer}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={LIBRARIES}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={12}
          onLoad={(mapInstance) => setMap(mapInstance)}
          options={{
            mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
          }}
        >
          {spots.length > 1 && (
            <Polyline
              path={spots.map((spot) => ({
                lat: spot.latitude,
                lng: spot.longitude,
              }))}
              options={{
                strokeColor: strokeColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default MapView;
