import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fetchCourseReviews } from '../../services/api/review/ReviewService';
import { Card, Container, Row, Col, Badge, Spinner, Alert, Modal } from 'react-bootstrap';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import axiosInstance from '../../services/api/axiosInstance';
import PaginationUtil from '../../components/pagination/PaginationUtil';
import './ReviewList.css';
import ReviewContent from './ReviewContent';

const processImages = async (imagePaths) => {
  try {
    const processedImages = await Promise.all(
      imagePaths.map(async (imagePath) => {
        if (imagePath.startsWith('https://lh3.googleusercontent.com') || imagePath.startsWith('https://lh3.google.com')) {
          return imagePath;
        }
        const response = await axiosInstance.get(imagePath, { responseType: 'blob' });
        return URL.createObjectURL(response.data);
      })
    );
    return processedImages;
  } catch (error) {
    console.error('❌ Failed to load images:', error);
    return ['https://via.placeholder.com/150'];
  }
};

function ReviewList() {
  const { courseId } = useParams();
  const location = useLocation();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalImages, setModalImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // 페이지네이션 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const handleShowModal = (images) => {
    setModalImages(images);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalImages([]);
    setShowModal(false);
  };

  useEffect(() => {
    const loadReviews = async () => {
      const numericCourseId = Number(courseId);
      if (isNaN(numericCourseId)) {
        setError('Invalid course ID. Please check the URL.');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await fetchCourseReviews(numericCourseId, currentPage - 1, itemsPerPage);

        if (response.retCode === '00') {
          const reviewData = response.data?.data || [];
          const totalItems = response.data?.totalItems || 0;

          const reviewsWithImages = await Promise.all(
            reviewData.map(async (review) => {
              const images = review.images?.length
                ? await processImages(review.images)
                : ['https://via.placeholder.com/150'];
              return { ...review, images };
            })
          );

          setReviews(reviewsWithImages);
          setTotalItems(totalItems);
          setError(null);
        } else {
          setError(response.retMessage || '리뷰를 불러오는 데 실패했습니다.');
        }
      } catch (err) {
        console.error('Error in loadReviews:', err);
        setError('Unexpected error occurred while fetching reviews.');
      } finally {
        setLoading(false);
      }
    };

    loadReviews();
  }, [courseId, currentPage]);

  if (loading) {
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <>
      <CustomNavbar />
      <Container className="review-container mt-5">
        <h2 className="text-center mb-4 review-title">리뷰 목록</h2>
        <Row>
          {reviews.length === 0 ? (
            <p className="text-center">리뷰가 없습니다.</p>
          ) : (
            reviews.map((review) => (
              <Col md={4} sm={6} xs={12} key={review.id} className="mb-4">
                <Card className="shadow-sm review-card">
                  <Card.Img
                    variant="top"
                    src={review.images[0]}
                    alt={`${review.content} 이미지`}
                    style={{ height: '180px', objectFit: 'cover' }}
                  />
                  {review.images.length > 1 && (
                    <Badge
                      pill
                      bg="info"
                      className="position-absolute top-0 start-0 m-2"
                      onClick={() => handleShowModal(review.images)}
                      style={{ cursor: 'pointer' }}
                    >
                      {review.images.length} 이미지
                    </Badge>
                  )}
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-between align-items-center">
                      <span className="review-author">{review.userName}</span>
                      <Badge bg="warning" text="dark">
                        ★ {review.stars.toFixed(1)}
                      </Badge>
                    </Card.Title>
                    <ReviewContent content={review.content} />
                    <div className="text-muted small">
                      <span>{review.createdAt}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>

        {/* Pagination */}
        <div className="pagination-container">
          <PaginationUtil
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>리뷰 이미지</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap justify-content-center">
            {modalImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Modal Image ${index + 1}`}
                style={{ width: '100%', marginBottom: '10px', maxHeight: '300px', objectFit: 'cover' }}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ReviewList;
