import React from 'react';
import { Carousel, Card } from 'react-bootstrap';
import './RecommendedSpots.module.css';

function RecommendedSpots({ recommendedSpots, onSwitchToPlanner }) {
  return (
    <div className="recommended-spots">
      <Carousel interval={null} className="custom-carousel">
        {recommendedSpots.map((spot) => (
          <Carousel.Item key={spot.id}>
            <Card className="custom-card">
              <Card.Img
                variant="top"
                src={spot.imageUrl || 'https://via.placeholder.com/800x500'}
                alt={spot.name}
              />
              <Card.Body>
                <Card.Title>{spot.name}</Card.Title>
                <Card.Text>{spot.description || '설명이 없습니다.'}</Card.Text>
              </Card.Body>
            </Card>
          </Carousel.Item>
        ))}
      </Carousel>
      <button onClick={onSwitchToPlanner} className="btn btn-primary mt-3">
        여행 일정 보기
      </button>
    </div>
  );
}

export default RecommendedSpots;
