// utils/apiUtils.js
import { validateEmail } from './validationUtils';
import { checkEmailDuplication } from '../services/api/auth/authApi';
import { setCookie, setRefreshTokenInCookie } from '../utils/cookieUtils'; // 쿠키 관련 유틸리티 함수 import


/**
 * 공통 입력 검증 함수
 * @param {Object} inputs - 검증할 입력 데이터 객체
 * @returns {Object | null} - 검증 실패 시 retCode와 retMessage 반환, 성공 시 null 반환
 */
const validateInputs = (inputs) => {
  const { email, password } = inputs;

  if (email === undefined || email === null || email.trim() === '') {
    return { retCode: '01', retMessage: '이메일을 입력해주세요.' };
  }

  if (!validateEmail(email)) {
    return { retCode: '01', retMessage: '이메일 형식이 올바르지 않습니다. (example@example.com)' };
  }

  if (password !== undefined && (password === null || password.trim() === '')) {
    return { retCode: '01', retMessage: '비밀번호를 입력해주세요.' };
  }

  return null; // 검증 통과
};

/**
 * API 응답 오류 처리 함수
 * @param {Object} error - 서버에서 반환된 에러 객체
 * @returns {Object} - retCode와 retMessage 포함
 */
const handleApiError = (error) => {
  console.error('❌ API 호출 오류:', error);

  // 서버에서 응답이 있는 경우
  if (error.response && error.response.data) {
    const { retCode, retMessage } = error.response.data;

    // retCode가 유효하면 그대로 반환
    return {
      retCode: retCode || '99', // retCode가 없으면 99 기본값
      retMessage: retMessage || '서버 오류로 요청이 처리되지 않았습니다.',
    };
  }

  // 서버 응답이 없는 네트워크 오류 처리
  return {
    retCode: '99',
    retMessage: '네트워크 오류로 요청을 처리할 수 없습니다.',
  };
};


/**
 * 이메일 중복 확인 함수
 * @param {string} email - 이메일 주소
 * @returns {Object} - retCode와 retMessage 포함
 */
export const checkEmailAvailability = async (email) => {
  const validationError = validateInputs({ email });
  if (validationError) return validationError;

  try {
    const response = await checkEmailDuplication(email);
    const { retCode, retMessage } = response.data;

    return { retCode, retMessage };
  } catch (error) {
    return handleApiError(error); // 개선된 에러 처리 함수 적용
  }
};


/**
 * 로그인 API 호출 함수
 * @param {string} email - 이메일 주소
 * @param {string} password - 비밀번호
 * @returns {Object} - API 호출 결과
 */
export const loginApi = async (email, password) => {
  const validationError = validateInputs({ email, password });
  if (validationError) return validationError;

  try {
    const response = await fetch('/api/v1/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    console.log('📥 Raw Response:', response);

    if (!response.ok) {
      const errorBody = await response.json();
      console.error('📥 Error Response Body:', errorBody);

      // 서버 응답에 따른 오류 처리
      return {
        retCode: errorBody.retCode || '99', // 서버가 retCode를 반환하면 우선 사용
        retMessage: errorBody.retMessage || '요청 처리 중 오류가 발생했습니다.',
      };
    }

    const result = await response.json();
    console.log('📥 Parsed Response JSON:', result);

    // 로그인 성공 시 액세스 토큰 및 리프레시 토큰 쿠키에 저장
    setCookie('accessToken', result.accessToken); // 액세스 토큰을 쿠키에 저장
    setRefreshTokenInCookie(result.refreshToken); // 리프레시 토큰을 쿠키에 저장

    return result;
  } catch (error) {
    return handleApiError(error); // 개선된 에러 처리 함수 적용
  }
};