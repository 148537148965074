import React from 'react';
import { Card } from 'react-bootstrap';
import './CardItem.css';

function CardItem({
  title,
  description,
  metaLeft,
  metaRight,
  imageUrl = 'https://via.placeholder.com/300',
  showBookmark = false,
  isBookmarked = false, // 부모에서 전달받는 북마크 상태
  onBookmarkToggle,
  onClick, // 카드 클릭 이벤트 핸들러 추가
}) {
  const handleBookmark = (event) => {
    event.stopPropagation(); // 북마크 클릭 시 카드 클릭 이벤트 차단
    if (onBookmarkToggle) {
      onBookmarkToggle(!isBookmarked); // 부모로 북마크 상태 반전 전달
    }
  };

  const handleCardClick = () => {
    if (onClick) {
      onClick(); // 부모에서 전달된 클릭 이벤트 실행
    }
  };

  return (
    <Card className="custom-card" onClick={handleCardClick} role="button">
      <Card.Img variant="top" src={imageUrl} alt="대표 이미지" />
      <Card.Body>
        <Card.Title className="custom-card-title">{title}</Card.Title>
        {description && <Card.Text className="custom-card-description">{description}</Card.Text>}
        <div className="custom-card-meta">
          {metaLeft && <span>{metaLeft}</span>}
          {metaRight && <span>{metaRight}</span>}
          {showBookmark && (
            <button
              className={`bookmark-btn ${isBookmarked ? 'active' : ''}`}
              onClick={handleBookmark}
              aria-label="Bookmark"
            >
              ♥
            </button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardItem;
