import axiosInstance from '../axiosInstance';
/**
 * Fetches reviews for a specific course.
 * @param {number} courseId - Course ID
 * @param {number} [page=0] - Page number (default: 0)
 * @param {number} [size=10] - Page size (default: 10)
 * @returns {Promise<Object>} - Review data or error object
 */
export const fetchCourseReviews = async (courseId, page = 0, size = 10) => {
    if (!courseId || isNaN(courseId)) {
      console.error('❌ Invalid courseId:', courseId);
      return { retCode: '99', retMessage: 'Invalid course ID' };
    }
  
    try {
      const response = await axiosInstance.get(`/api/v1/course/${courseId}/review`, {
        params: { page, size },
      });
  
      if (response.status === 200) {
        return response.data; // 성공 시 JSON 데이터 반환
      } else {
        console.error('Unexpected response status:', response.status);
        return { retCode: '99', retMessage: 'Unexpected response status' };
      }
    } catch (error) {
      console.error('❌ Failed to fetch course reviews:', error.response || error.message);
      return { retCode: '99', retMessage: 'Failed to fetch reviews' }; // 에러 시 기본 메시지 반환
    }
  };
  


/**
 * Submits a new review for a specific course.
 * @param {string} token - Bearer token for authorization
 * @param {number} courseId - Course ID
 * @param {Object} createRequest - Review content and star rating
 * @param {Array<File>} images - Array of image files
 * @returns {Promise<Object>} - Response data or error object
 */
export const submitCourseReview = async (token, courseId, createRequest, images) => {
    const formData = new FormData();
    formData.append('createRequest', new Blob([JSON.stringify(createRequest)], { type: 'application/json' }));
  
    images.forEach((image) => {
      formData.append('images', image);
    });
  
    try {
      const response = await axiosInstance.post(`/api/v1/course/${courseId}/review`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      // 성공 상태 코드를 200 또는 201로 처리
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        console.error('Unexpected response status:', response.status);
        return { retCode: '99', retMessage: 'Unexpected response status' };
      }
    } catch (error) {
      console.error('❌ Failed to submit review:', error.response || error.message);
      return { retCode: '99', retMessage: 'Failed to submit review' };
    }
  };
  
/**
 * Fetches reviews written by the user.
 * @param {number} [page=0] - Page number (default: 0)
 * @param {number} [size=10] - Page size (default: 10)
 * @returns {Promise<Object>} - Review data or error object
 */
export const fetchUserReviews = async (page = 0, size = 10) => {
  try {
    const response = await axiosInstance.get('/api/v1/users/review', {
      params: { page, size },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.status === 200) {
      console.log('Fetched Reviews:', response.data.data); // courseId 포함 여부 확인
      return response.data; // 성공 시 JSON 데이터 반환
    } else {
      console.error('Unexpected response status:', response.status);
      return { retCode: '99', retMessage: 'Unexpected response status' };
    }
  } catch (error) {
    console.error('❌ Failed to fetch course reviews:', error.response || error.message);
    return { retCode: '99', retMessage: 'Failed to fetch reviews' }; // 에러 시 기본 메시지 반환
  }
};

  
  /**
 * Updates a review for a specific course.
 * @param {number} courseId - Course ID
 * @param {number} reviewId - Review ID
 * @param {Object} updateRequest - Updated review content and star rating
 * @returns {Promise<Object>} - Response data or error object
 */
export const updateReview = async (courseId, reviewId, updateRequest) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/course/${courseId}/review/${reviewId}`,
      updateRequest,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data; // 성공 시 JSON 데이터 반환
    } else {
      console.error('Unexpected response status:', response.status);
      return { retCode: '99', retMessage: 'Unexpected response status' };
    }
  } catch (error) {
    console.error('❌ Failed to update review:', error.response || error.message);
    return { retCode: '99', retMessage: 'Failed to update review' }; // 에러 시 기본 메시지 반환
  }
};

/**
 * Deletes a review for a specific course.
 * @param {number} courseId - Course ID
 * @param {number} reviewId - Review ID
 * @returns {Promise<Object>} - Response data or error object
 */
export const deleteReview = async (courseId, reviewId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/users/review/${reviewId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.status === 200) {
      return response.data; // 성공 시 JSON 데이터 반환
    } else {
      console.error('Unexpected response status:', response.status);
      return { retCode: '99', retMessage: 'Unexpected response status' };
    }
  } catch (error) {
    console.error('❌ Failed to delete review:', error.response || error.message);
    return { retCode: '99', retMessage: 'Failed to delete review' }; // 에러 시 기본 메시지 반환
  }
};