import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import AuthRoute from './routes/AuthRoute'; // AuthRoute를 새로 생성하여 사용
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import SignUp from './pages/signup/SignUp';
import Main from './pages/main/Main';
import MyPage from './pages/myPage/MyPage';
import MyBookmark from './pages/myPage/bookmark/MyBookmark';
import CourseMain from './pages/course/create/CourseMain';
import MapPage from './pages/course/create/map/MapPage';
import CourseList from './pages/course/get/CourseList';
import RecordList from './pages/record/RecordList';
import RecordCreate from './pages/record/RecordCreate';
import CourseDetail from './pages/course/get/CourseDetail';
import RecordDetail from './pages/record/RecordDetail';
import ReviewCreate from './pages/review/ReviewCreate';
import ReviewList from './pages/review/ReviewList';
import LeftColumn from './pages/course/create/leftColumn/LeftColumn';
import CenterColumn from './pages/course/create/centerColumn/CenterColumn';
import React, { useState } from 'react';

// TravelPlanner 컴포넌트: LeftColumn과 CenterColumn 연결
function TravelPlanner() {
  const location = useLocation();
  const { destination, duration } = location.state || {}; // Main.js에서 전달된 state 값
  // 추가된 상태 관리: addedPlace를 통해 LeftColumn과 CenterColumn 간 데이터 연동
  const [addedPlace, setAddedPlace] = useState(null);
  // LeftColumn에서 CenterColumn으로 데이터를 전달하기 위한 함수
  const handleAddToCenterColumn = (place) => {
    console.log('추가된 장소 또는 숙소:', place);
    setAddedPlace(place);
  };
  if (!destination || !duration) {
    return <div>여행지와 기간을 선택하세요!</div>; // state 값이 없을 때의 처리
  }
  return (
    <div style={{ display: 'flex' }}>
      <LeftColumn
        destination={destination} // Main.js에서 전달된 여행지
        duration={duration} // Main.js에서 전달된 기간
        showCourse={true}
        onAddToCenterColumn={handleAddToCenterColumn}
      />
      <CenterColumn
        totalDays={duration}
        showCourse={true}
        addedPlace={addedPlace}
      />
    </div>
  );
}

function App() {
  console.log('App 컴포넌트 렌더링 시작');

  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <AuthRoute isPublic allowPaths={['/login', '/signup']}>
                <Home />
              </AuthRoute>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRoute isPublic>
                <Login />
              </AuthRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <AuthRoute isPublic>
                <SignUp />
              </AuthRoute>
            }
          />

          {/* Private Routes */}
          <Route
            path="/main"
            element={
              <AuthRoute>
                <Main />
              </AuthRoute>
            }
          />
          <Route
            path="/mypage"
            element={
              <AuthRoute>
                <MyPage />
              </AuthRoute>
            }
          />
          <Route
            path="/bookmark"
            element={
              <AuthRoute>
                <MyBookmark />
              </AuthRoute>
            }
          />
          <Route
            path="/course"
            element={
              <AuthRoute>
                <CourseMain />
              </AuthRoute>
            }
          />
          <Route
            path="/map"
            element={
              <AuthRoute>
                <MapPage />
              </AuthRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <AuthRoute>
                <CourseList />
              </AuthRoute>
            }
          />
          <Route
            path="/records"
            element={
              <AuthRoute>
                <RecordList />
              </AuthRoute>
            }
          />
          <Route
            path="/courses/:courseId"
            element={
              <AuthRoute>
                <CourseDetail />
              </AuthRoute>
            }
          />
          <Route
            path="/records/:recordId"
            element={
              <AuthRoute>
                <RecordDetail />
              </AuthRoute>
            }
          />
          <Route
            path="/records/create"
            element={
              <AuthRoute>
                <RecordCreate />
              </AuthRoute>
            }
          />
          <Route
            path="/course/:courseId/reviews"
            element={
              <AuthRoute>
                <ReviewList />
              </AuthRoute>
            }
          />
          <Route
            path="/course/:courseId/create"
            element={
              <AuthRoute>
                <ReviewCreate />
              </AuthRoute>
            }
          />
          <Route
            path="/reviews"
            element={
              <AuthRoute>
                <ReviewList />
              </AuthRoute>
            }
          />
           <Route
            path="/travel-planner" element={<TravelPlanner />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
