import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import 'react-quill/dist/quill.snow.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createRecordPost } from '../../services/api/record/RecordCreateService';
import { getTravelCourses } from '../../services/api/course/CourseListService';
import './RecordCreate.css';
import CustomNavbar from '../../components/navbar/CustomNavbar';

const MAX_CONTENT_LENGTH = 4000;

const RecordCreate = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseOptions, setCourseOptions] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [contentLengthError, setContentLengthError] = useState(false);
    const quillRef = useRef(null);

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            try {
                const response = await getTravelCourses(0, 100);
                const options = response.data.map(course => ({
                    value: course.id,
                    label: course.title
                }));
                setCourseOptions(options);
            } catch (err) {
                console.error('Failed to fetch courses:', err);
                setError('Failed to load course options.');
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, []);

    const handleImageSelection = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', 'multiple');
        input.click();

        input.onchange = () => {
            const files = Array.from(input.files);
            setImages(prevImages => [...prevImages, ...files]);
        };
    };

    const handleContentChange = (value) => {
        setContent(value);
        const strippedContent = value.replace(/<[^>]*>/g, '').trim();
        setContentLengthError(strippedContent.length > MAX_CONTENT_LENGTH);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!selectedCourse) {
            alert('Please select a course.');
            return;
        }
        if (!title.trim()) {
            alert('Please enter a title.');
            return;
        }
        if (!content.trim()) {
            alert('Please enter some content.');
            return;
        }

        const strippedContent = content.replace(/<[^>]*>/g, '').trim();
        if (strippedContent.length > MAX_CONTENT_LENGTH) {
            alert(`Content cannot exceed ${MAX_CONTENT_LENGTH} characters.`);
            return;
        }

        const createRequest = { title, content };

        try {
            const response = await createRecordPost(selectedCourse.value, createRequest, images);
            if (response.retCode === '00') {
                alert('Post created successfully!');
                navigate(`/records/${response.data.postId}`);
            } else {
                alert(response.retMessage || 'Failed to create post.');
            }
        } catch (err) {
            console.error('Error creating post:', err);
            alert('An error occurred while creating the post.');
        }
    };

    const handleImageRemove = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image'],
            ],
            handlers: {
                image: handleImageSelection,
            },
        },
    }), []);

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'link',
        'image',
    ];

    return (
        <>
        <CustomNavbar />
        <div className="record-create-container">
            <form onSubmit={handleSubmit} className="record-create-form">
                <h2 className="record-create-form-title">새 여행 기록</h2>
                <div className="form-group mb-3">
                    <label htmlFor="course">코스</label>
                    {loading ? (
                        <Spinner animation="border" size="sm" />
                    ) : error ? (
                        <div className="text-danger">{error}</div>
                    ) : (
                        <Select
                            id="course"
                            value={selectedCourse}
                            onChange={setSelectedCourse}
                            options={courseOptions}
                            placeholder="기록할 코스를 선택하세요"
                        />
                    )}
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="title">제목</label>
                    <input
                        type="text"
                        id="title"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="제목을 입력하세요"
                    />
                </div>

                <div className="form-group mb-3">
                    <label htmlFor="content">내용</label>
                    <ReactQuill
                        ref={quillRef}
                        value={content}
                        onChange={handleContentChange}
                        modules={modules}
                        formats={formats}
                        placeholder="내용을 입력하세요"
                    />
                    {contentLengthError && (
                        <div className="text-danger">
                            Content cannot exceed {MAX_CONTENT_LENGTH} characters.
                        </div>
                    )}
                </div>

                {images.length > 0 && (
                    <div className="image-preview-container">
                        <label>Selected Images</label>
                        <div className="d-flex flex-wrap">
                            {images.map((image, index) => (
                                <div key={index} className="image-preview position-relative">
                                    <img
                                        src={URL.createObjectURL(image)}
                                        alt={`preview-${index}`}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm position-absolute"
                                        onClick={() => handleImageRemove(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <button type="submit" className="btn btn-primary mt-3">
                    등록하기
                </button>
            </form>
        </div>
        </>
    );
};

export default RecordCreate;
