import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Container, Spinner, Alert, Badge, Modal, Dropdown, Button, Form } from 'react-bootstrap';
import PaginationUtil from '../../../components/pagination/PaginationUtil';
import { fetchUserReviews, updateReview, deleteReview } from '../../../services/api/review/ReviewService';
import './MyReview.css';
import axiosInstance from '../../../services/api/axiosInstance';
import ReviewContent from '../../../pages/review/ReviewContent';

const processImage = async (imagePaths) => {
  try {
    const paths = Array.isArray(imagePaths) ? imagePaths : [imagePaths];

    const processedImages = await Promise.all(
      paths.map(async (imagePath) => {
        if (imagePath.startsWith('https://lh3.googleusercontent.com') || imagePath.startsWith('https://lh3.google.com')) {
          return imagePath;
        }
        const response = await axiosInstance.get(imagePath, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        return URL.createObjectURL(response.data);
      })
    );

    return processedImages;
  } catch (error) {
    console.error('❌ Failed to load images:', error);
    return ['https://via.placeholder.com/150'];
  }
};

function MyReview() {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (images) => {
    setModalImages(images);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalImages([]);
    setShowModal(false);
  };

  useEffect(() => {
    const loadUserReviews = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetchUserReviews(currentPage - 1, itemsPerPage);
        if (response.retCode === '00' && response.data) {
          const { data: reviewData, totalItems } = response.data;

          const reviewsWithImages = await Promise.all(
            reviewData.map(async (review) => {
              const imagePaths = Array.isArray(review.images) ? review.images : [];
              const images = imagePaths.length > 0 ? await processImage(imagePaths) : ['https://via.placeholder.com/150'];
              return { ...review, images };
            })
          );

          setReviews(reviewsWithImages);
          setTotalItems(totalItems);
        } else {
          throw new Error(response.retMessage || 'Unexpected API response');
        }
      } catch (error) {
        console.error('Failed to fetch user reviews:', error);
        setError(error.message || 'Failed to load user reviews.');
      } finally {
        setLoading(false);
      }
    };

    loadUserReviews();
  }, [currentPage]);

  return (
    <Container className="my-reviews-container">
      <h2 className="my-reviews-title">리뷰 목록</h2>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <>
          <Row>
            {reviews.map((review) => (
              <Col md={4} sm={6} xs={12} key={review.id} className="mb-4">
                <Card className="review-card">
                  <Card.Img
                    variant="top"
                    src={review.images[0]}
                    alt={`${review.content} 이미지`}
                    className="review-image"
                  />
                  {review.images.length > 1 && (
                    <Badge
                      pill
                      bg="info"
                      className="image-count-badge"
                      onClick={() => handleShowModal(review.images)}
                      style={{ cursor: 'pointer' }}
                    >
                      {review.images.length} 이미지
                    </Badge>
                  )}
                  <Card.Body>
                    <Card.Title className="d-flex justify-content-between align-items-center">
                      <span>{review.userName}</span>
                      <Dropdown>
                        <Dropdown.Toggle className="iconButton">
                          ⋮
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>수정</Dropdown.Item>
                          <Dropdown.Item>삭제</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Card.Title>
                    <ReviewContent content={review.content} />
                    <div className="text-muted small">{review.createdAt}</div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          {/* Pagination */}
          <div className="pagination-container">
            <PaginationUtil
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}

      {/* Image Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>리뷰 이미지</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap justify-content-center">
            {modalImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Modal Image ${index + 1}`}
                style={{ width: '100%', marginBottom: '10px', maxHeight: '300px', objectFit: 'cover' }}
              />
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default MyReview;
