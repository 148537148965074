import React, { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import styles from './InputText.module.css';

function InputText({ onMessageSend }) {
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (message.trim() !== '') {
      onMessageSend(message);
      setMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="채팅을 입력하세요..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Button variant="primary" onClick={handleSubmit}>
          전송
        </Button>
      </InputGroup>
    </div>
  );
}

export default InputText;