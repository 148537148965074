import React from 'react';
import { Form } from 'react-bootstrap';
import './PasswordField.css'; // 추가된 CSS 파일

function PasswordField({ value, onChange, message, isValid }) {
  return (
    <Form.Group className="password-field-container" controlId="formPassword">
      <Form.Label>비밀번호</Form.Label>
      <Form.Control
        type="password"
        placeholder="비밀번호 입력(문자, 숫자, 특수문자 포함 8~20자)"
        name="password"
        value={value}
        onChange={onChange}
        required
      />
      {/* 메시지 영역 */}
      <div className={`password-message ${isValid ? 'valid' : 'invalid'}`}>
        {message}
      </div>
    </Form.Group>
  );
}

export default PasswordField;
