import React, { useState } from 'react';
import styles from './SidebarMenu.module.css';

function SidebarMenu({ setSelectedMenu }) {
  // 현재 선택된 메뉴 상태 관리
  const [activeMenu, setActiveMenu] = useState('');

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName); // 클릭된 메뉴를 상태로 설정
    setSelectedMenu(menuName); // 상위 컴포넌트에 선택된 메뉴 알림
  };

  return (
    <div className={styles.sidebarMenu}>
      <h5 className="fw-bold mb-4">마이페이지</h5>
      <ul className="list-unstyled">
        <li className={`${styles.menuItem} ${activeMenu === '회원정보 수정' ? styles.active : ''}`}>
          <a
            onClick={() => handleMenuClick('회원정보 수정')}
            className={`${styles.link}`}
          >
            회원정보 수정
          </a>
        </li>
        <li className={`${styles.menuItem} ${activeMenu === '내 여행' ? styles.active : ''}`}>
          <a
            onClick={() => handleMenuClick('내 여행')}
            className={`${styles.link}`}
          >
            내 여행
          </a>
        </li>
        <li className={`${styles.menuItem} ${activeMenu === '내 여행 기록' ? styles.active : ''}`}>
          <a
            onClick={() => handleMenuClick('내 여행 기록')}
            className={`${styles.link}`}
          >
            내 여행기록
          </a>
        </li>
        <li className={`${styles.menuItem} ${activeMenu === '내 리뷰' ? styles.active : ''}`}>
          <a
            onClick={() => handleMenuClick('내 리뷰')}
            className={`${styles.link}`}
          >
            내 리뷰
          </a>
        </li>
        <li className={`${styles.menuItem} ${activeMenu === '회원 탈퇴' ? styles.active : ''}`}>
          <a
            onClick={() => handleMenuClick('회원 탈퇴')}
            className={`${styles.link}`}
          >
            회원 탈퇴
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SidebarMenu;
