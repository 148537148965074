import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { getComments, addComment, addReply, updateComment, deleteComment } from '../../services/api/comment/CommentService';
import PaginationUtil from '../../components/pagination/PaginationUtil';
import commentStyles from './RecordDetailComments.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthContext'; // AuthContext에서 useAuth 가져오기

function CommentSection({ recordId }) {
    const { userId } = useAuth(); // 로그인된 유저 정보 가져오기
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyStates, setReplyStates] = useState({});
    const [replyTexts, setReplyTexts] = useState({});
    const [editStates, setEditStates] = useState({});
    const [editedTexts, setEditedTexts] = useState({});
    const [commentError, setCommentError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const { comments, totalPages } = await getComments(recordId, currentPage - 1, 5);
                setComments(comments);
                setTotalPages(totalPages);
            } catch (err) {
                console.error(err);
                setCommentError('Failed to load comments.');
            }
        };
        fetchComments();
    }, [recordId, currentPage]);


    const handleCommentSubmit = async () => {
        if (!newComment.trim()) return;

        try {
            const createdComment = await addComment(recordId, newComment);
            setComments((prev) => [createdComment, ...prev]);
            setNewComment('');
        } catch (err) {
            console.error(err);
            setCommentError('Failed to add comment.');
        }
    };

    const handleEditSubmit = async (commentId, isReply = false, parentCommentId = null) => {
        const editedText = editedTexts[commentId];
        if (!editedText.trim()) return;

        // Optimistic UI 업데이트
        setComments((prev) =>
            prev.map((comment) => {
                if (isReply && parentCommentId === comment.commentId) {
                    return {
                        ...comment,
                        replies: comment.replies.map((reply) =>
                            reply.commentId === commentId ? { ...reply, content: editedText } : reply
                        ),
                    };
                } else if (comment.commentId === commentId) {
                    return { ...comment, content: editedText };
                }
                return comment;
            })
        );

        setEditStates((prev) => ({ ...prev, [commentId]: false }));
        setEditedTexts((prev) => ({ ...prev, [commentId]: '' }));

        try {
            await updateComment(recordId, commentId, editedText);
        } catch (err) {
            console.error('Failed to update comment:', err);
            setCommentError('Failed to update comment.');
        }
    };

    const handleDeleteComment = async (commentId, isReply = false, parentCommentId = null) => {
        let deletedComment = null;

        // Optimistic UI 업데이트
        setComments((prev) =>
            prev.map((comment) => {
                if (isReply && parentCommentId === comment.commentId) {
                    deletedComment = comment.replies.find((reply) => reply.commentId === commentId);
                    return {
                        ...comment,
                        replies: comment.replies.filter((reply) => reply.commentId !== commentId),
                    };
                } else if (comment.commentId === commentId) {
                    deletedComment = comment;
                    return null; // 삭제
                }
                return comment;
            }).filter(Boolean) // null 제거
        );

        try {
            await deleteComment(recordId, commentId);
        } catch (err) {
            console.error('Failed to delete comment:', err);
            setCommentError('Failed to delete comment.');

            // 롤백 처리
            if (deletedComment) {
                setComments((prev) => {
                    if (isReply && parentCommentId) {
                        return prev.map((comment) =>
                            comment.commentId === parentCommentId
                                ? {
                                      ...comment,
                                      replies: [...(comment.replies || []), deletedComment],
                                  }
                                : comment
                        );
                    }
                    return [...prev, deletedComment];
                });
            }
        }
    };

    const handleReplySubmit = async (commentId) => {
        const replyText = replyTexts[commentId] || '';
        if (!replyText.trim()) return;

        const tempReply = {
            commentId: `temp-${Date.now()}`,
            content: replyText,
            userName: 'You',
            createdAt: new Date().toISOString(),
        };

        // Optimistic UI 업데이트
        setComments((prev) =>
            prev.map((comment) =>
                comment.commentId === commentId
                    ? { ...comment, replies: [...(comment.replies || []), tempReply] }
                    : comment
            )
        );

        setReplyStates((prev) => ({ ...prev, [commentId]: false }));
        setReplyTexts((prev) => ({ ...prev, [commentId]: '' }));

        try {
            const createdReply = await addReply(recordId, replyText, commentId);
            setComments((prev) =>
                prev.map((comment) =>
                    comment.commentId === commentId
                        ? {
                              ...comment,
                              replies: comment.replies.map((reply) =>
                                  reply.commentId === tempReply.commentId
                                      ? createdReply
                                      : reply
                              ),
                          }
                        : comment
                )
            );
        } catch (err) {
            console.error('Failed to add reply:', err);
            setCommentError('Failed to add reply.');
        }
    };
    const renderReplies = (replies, parentCommentId) =>
        replies && replies.length > 0 ? (
            <div className={commentStyles.repliesList}>
                {replies.map((reply) => (
                    <div key={reply.commentId} className={commentStyles.replyItem}>
                        <div className={commentStyles.replyHeader}>
                            <span className={commentStyles.userName}>{reply.userName}</span>
                            {reply.userId === userId && ( // 본인 확인 조건 추가
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="secondary"
                                        size="sm"
                                        className={commentStyles.dropdownButton}
                                    >
                                        ⋮
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {!reply.isDeleted && (
                                            <>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        setEditStates((prev) => ({
                                                            ...prev,
                                                            [reply.commentId]: !prev[reply.commentId],
                                                        }))
                                                    }
                                                >
                                                    수정
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        handleDeleteComment(reply.commentId, true, parentCommentId)
                                                    }
                                                >
                                                    삭제
                                                </Dropdown.Item>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>

                        {reply.isDeleted ? (
                            <div className={commentStyles.deletedComment}>삭제된 댓글입니다.</div>
                        ) : editStates[reply.commentId] ? (
                            <div>
                                <textarea
                                    value={editedTexts[reply.commentId] || reply.content}
                                    onChange={(e) =>
                                        setEditedTexts((prev) => ({
                                            ...prev,
                                            [reply.commentId]: e.target.value,
                                        }))
                                    }
                                    className={commentStyles.commentTextarea}
                                />
                                <Button
                                    onClick={() =>
                                        handleEditSubmit(reply.commentId, true, parentCommentId)
                                    }
                                    className={commentStyles.submitButton}
                                >
                                    수정 완료
                                </Button>
                            </div>
                        ) : (
                            <div className={commentStyles.replyContent}>{reply.content}</div>
                        )}
                        <div className={commentStyles.replyMeta}>
                            <span className={commentStyles.replyDate}>
                                {new Date(reply.createdAt).toLocaleString()}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        ) : null;

    return (
        <div className={commentStyles.commentSection}>
            <div className={commentStyles.textareaWithIcon}>
                <textarea
                    className={commentStyles.commentTextarea}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="댓글을 입력하세요."
                />
                <button
                    onClick={handleCommentSubmit}
                    className={commentStyles.iconButton}
                    title="댓글 작성"
                >
                    <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>

            {commentError && <p className={commentStyles.error}>{commentError}</p>}
            <div className={commentStyles.commentList}>
                {comments.map((comment) => (
                    <div key={comment.commentId} className={commentStyles.commentItem}>
                        <div className={commentStyles.commentHeader}>
                            <span className={commentStyles.userName}>{comment.userName}</span>
                            {comment.userId === userId && ( // 본인 확인 조건 추가
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="secondary"
                                        size="sm"
                                        className={commentStyles.dropdownButton}
                                    >
                                        ⋮
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {!comment.isDeleted && (
                                            <>
                                                <Dropdown.Item
                                                    onClick={() =>
                                                        setEditStates((prev) => ({
                                                            ...prev,
                                                            [comment.commentId]: !prev[comment.commentId],
                                                        }))
                                                    }
                                                >
                                                    수정
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={() => handleDeleteComment(comment.commentId)}
                                                >
                                                    삭제
                                                </Dropdown.Item>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>

                        {comment.isDeleted ? (
                            <div className={commentStyles.deletedComment}>삭제된 댓글입니다.</div>
                        ) : editStates[comment.commentId] ? (
                            <div>
                                <textarea
                                    value={editedTexts[comment.commentId] || comment.content}
                                    onChange={(e) =>
                                        setEditedTexts((prev) => ({
                                            ...prev,
                                            [comment.commentId]: e.target.value,
                                        }))
                                    }
                                    className={commentStyles.commentTextarea}
                                />
                                <Button
                                    onClick={() => handleEditSubmit(comment.commentId)}
                                    className={commentStyles.submitButton}
                                >
                                    수정 완료
                                </Button>
                            </div>
                        ) : (
                            <div className={commentStyles.commentContent}>{comment.content}</div>
                        )}
                        <div className={commentStyles.commentMeta}>
                            <span className={commentStyles.commentDate}>
                                {new Date(comment.createdAt).toLocaleString()}
                            </span>
                            <Button
                                variant="link"
                                className={commentStyles.replyButton}
                                onClick={() =>
                                    setReplyStates((prev) => ({
                                        ...prev,
                                        [comment.commentId]: !prev[comment.commentId],
                                    }))
                                }
                            >
                                답글쓰기
                            </Button>
                        </div>

                        {replyStates[comment.commentId] && (
                            <div className={commentStyles.replyInputContainer}>
                                <div className={commentStyles.textareaWithIcon}>
                                    <textarea
                                        className={commentStyles.commentTextarea}
                                        value={replyTexts[comment.commentId] || ''}
                                        onChange={(e) =>
                                            setReplyTexts((prev) => ({
                                                ...prev,
                                                [comment.commentId]: e.target.value,
                                            }))
                                        }
                                        placeholder="답글을 입력하세요."
                                    />
                                    <button
                                        onClick={() => handleReplySubmit(comment.commentId)}
                                        className={commentStyles.iconButton}
                                        title="답글 작성"
                                    >
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </button>
                                </div>
                            </div>
                        )}

                        {renderReplies(comment.replies, comment.commentId)}
                    </div>
                ))}
            </div>
            <div className="pagination-container">
            <PaginationUtil
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
            />
            </div>
        </div>
    );
}

export default CommentSection;
