// DaySelector.js
import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import styles from './DaySelector.module.css';

function DaySelector({ totalDays, onDayChange }) {
  const [selectedDay, setSelectedDay] = useState(1);

const handleDayClick = (day) => {
  if (day !== selectedDay) {
    console.log(`Day ${day} clicked`); // 선택된 날짜 로그
    setSelectedDay(day);  // selectedDay 상태 업데이트
    onDayChange(day);     // 부모 컴포넌트에서 selectedDay 반영
  }
};


  return (
    <div className={styles.centeredContainer}>
      <div className={styles.daySelectorContainer}>
        <ButtonGroup className={styles.daySelector}>
          {[...Array(totalDays)].map((_, index) => {
            const day = index + 1;
            const isSelected = day === selectedDay;

            return (
              <React.Fragment key={day}>
                <Button
                  className={`${styles.dayButton} ${isSelected ? styles.selectedDay : ''}`}
                  onClick={() => handleDayClick(day)}
                  aria-pressed={isSelected}
                >
                  {day}
                </Button>
                {day < totalDays && <div className={styles.line} />}
              </React.Fragment>
            );
          })}
        </ButtonGroup>
      </div>
    </div>
  );
}

export default DaySelector;
