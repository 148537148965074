import React, { useState, useRef, useEffect } from 'react';
import InputText from './InputText';
import styles from './RightColumn.module.css';
import ReactMarkdown from 'react-markdown';
import { sendMessageToChatbot } from '../../../../services/api/course/CourseCreateService';

function RightColumn({
  onShowCourses,
  destination,
  duration,
  sessionId,
  initialBotMessage,
  onAddBotResponse, // Planner에 응답 데이터를 추가하기 위한 함수
}) {
  const [chatMessages, setChatMessages] = useState([]);
  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (initialBotMessage) {
      let i = 0;
      const interval = setInterval(() => {
        setChatMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { type: 'bot', text: initialBotMessage.slice(0, i + 1) },
        ]);
        i++;
        if (i === initialBotMessage.length) {
          clearInterval(interval);
          onShowCourses(); // 메시지 출력 후 일정 표시
        }
      }, 50);
      setChatMessages([{ type: 'bot', text: '' }]); // 초기 메시지
    }
  }, [initialBotMessage, onShowCourses]);

  const handleMessageSend = async (message) => {
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: 'user', text: message },
    ]);
  
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { type: 'bot', text: '로딩 중...' },
    ]);
  
    try {
      const dialogResponse = await sendMessageToChatbot(sessionId, message);
  
      if (dialogResponse.success) {
        const botResponse = dialogResponse.data.response;
        const botMessage = botResponse.message;
        const recommendations = botResponse.recommendations;
        const tableName = botResponse.table; // 상위 table 값 가져오기
  
        if (botMessage === 'JSON 파싱에 실패했습니다.') {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: 'bot', text: '질문을 이해하지 못했습니다. 다시 시도해 주세요.' },
          ]);
        } else {
          setChatMessages((prevMessages) => [
            ...prevMessages,
            { type: 'bot', text: botMessage },
          ]);
  
          if (recommendations && recommendations.length > 0) {
            // 전달된 onAddBotResponse 호출
            onAddBotResponse(recommendations, tableName); 
          }
        }
      } else {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { type: 'bot', text: '챗봇 응답을 가져오지 못했습니다. 다시 시도해 주세요.' },
        ]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { type: 'bot', text: '오류가 발생했습니다. 다시 시도해 주세요.' },
      ]);
    }
  };
  
  
  
  

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <div className={styles.columnContent}>
      <div className={styles.chatArea} ref={chatBoxRef}>
        {chatMessages.map((msg, index) => (
          <div
            key={index}
            className={
              msg.type === 'user' ? styles.userMessage : styles.botMessage
            }
          >
            {msg.type === 'bot' ? (
              <ReactMarkdown>{msg.text}</ReactMarkdown> // Markdown 처리
            ) : (
              msg.text
            )}
          </div>
        ))}
      </div>
      <InputText onMessageSend={handleMessageSend} />
    </div>
  );
}

export default RightColumn;
