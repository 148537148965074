import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AuthRoute = ({ children, isPublic = false, allowPaths = [] }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // Public route: 인증된 경우 메인 페이지로 이동
  if (isPublic && isAuthenticated) {
    return <Navigate to="/main" replace />;
  }

  // Private route: 인증되지 않은 경우 로그인 페이지로 이동 (허용된 경로 제외)
  if (!isPublic && !isAuthenticated && !allowPaths.includes(location.pathname)) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default AuthRoute;
