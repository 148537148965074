import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { FaShare, FaDownload, FaFilePdf } from 'react-icons/fa';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import MapView from '../../map/MapView';
import { getCourseDetails } from '../../../services/api/course/CourseDetailService';
import { fetchCourseReviews } from '../../../services/api/review/ReviewService';
import CourseSpots from './CourseSpots';
import AlertModal from '../../../components/common/AlertModal';
import styles from './CourseDetail.module.css';

function CourseDetail() {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const [courseDetail, setCourseDetail] = useState({
    title: '',
    spots: [],
  });
  const [selectedDay, setSelectedDay] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  // Kakao SDK 초기화
  useEffect(() => {
    const initKakaoSDK = () => {
      // Check if Kakao is available in the global window object
      if (window.Kakao) {
        // Check if Kakao is not already initialized
        if (!window.Kakao.isInitialized()) {
          try {
            // Initialize Kakao with your JavaScript key
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            console.log('Kakao SDK successfully initialized');
          } catch (error) {
            console.error('Failed to initialize Kakao SDK:', error);
          }
        }
      } else {
        // Load Kakao SDK script dynamically if not already loaded
        const script = document.createElement('script');
        script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js';
        script.async = true;
        script.onload = () => {
          try {
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            console.log('Kakao SDK loaded and initialized');
          } catch (error) {
            console.error('Failed to initialize Kakao SDK:', error);
          }
        };
        script.onerror = () => {
          console.error('Failed to load Kakao SDK script');
        };
        document.head.appendChild(script);
      }
    };
  
    initKakaoSDK();
  }, []);
  

  useEffect(() => {
    const numericCourseId = Number(courseId);
    if (isNaN(numericCourseId)) {
      setError('Invalid course ID. Please check the URL.');
      setLoading(false);
      return;
    }

    const fetchCourseDetail = async () => {
      try {
        const data = await getCourseDetails(numericCourseId);
        setCourseDetail({
          title: data.title || '',
          spots: data.spots || [],
        });
      } catch (err) {
        setError('Failed to load course details. Please try again.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetail();
  }, [courseId]);

  if (loading) return <div className={styles.loader}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  const currentDaySpots = courseDetail.spots.find(
    (day) => day.dayNumber === selectedDay
  );

  const combinedSpots = currentDaySpots
    ? [
        ...currentDaySpots.accommodations,
        ...currentDaySpots.restaurants,
        ...currentDaySpots.touristAttractions,
      ]
    : [];

  const handleReviewButtonClick = async () => {
    try {
      const response = await fetchCourseReviews(Number(courseId), 0, 10);
      if (response.retCode === '00' && response.data?.data?.length > 0) {
        navigate(`/course/${courseId}/reviews`);
      } else {
        setModalMessage('등록된 리뷰가 없습니다.');
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setModalMessage('리뷰를 불러오는 중 오류가 발생했습니다.');
      setShowModal(true);
    }
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById('spots-section');
    if (!element) return;

    const canvas = await html2canvas(element);
    const imageData = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = imageData;
    link.download = `${courseDetail.title}_spots.png`;
    link.click();
  };

  const handleDownloadPDF = async () => {
    const element = document.getElementById('spots-section');
    if (!element) return;

    const canvas = await html2canvas(element);
    const imageData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${courseDetail.title}_spots.pdf`);
  };

  // Kakao 공유하기 핸들러
  const handleKakaoShare = () => {
    if (!window.Kakao || !window.Kakao.isInitialized()) {
      console.error('Kakao SDK is not initialized.');
      alert('카카오 공유 기능을 준비 중입니다. 잠시 후 다시 시도해주세요.');
      return;
    }
  
    // 유효한 이미지 URL 또는 기본 이미지 URL 사용
    const imageUrl = 
      courseDetail.imageUrl || 
      'https://example.com/default-course-image.jpg'; // 실제 존재하는 이미지 URL로 대체
  
    try {
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: courseDetail.title,
          description: '여행 코스를 공유합니다!',
          imageUrl: imageUrl, // 유효한 이미지 URL 사용
          link: {
            mobileWebUrl: window.location.href,
            webUrl: window.location.href,
          },
        },
        buttons: [
          {
            title: '자세히 보기',
            link: {
              mobileWebUrl: window.location.href,
              webUrl: window.location.href,
            },
          },
        ],
      });
    } catch (error) {
      console.error('Kakao share failed:', error);
      alert('공유 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };
  

  return (
    <>
      <CustomNavbar />
      <Container fluid className={styles.pageContainer}>
        <Row className={styles.mainRow}>
          <Col md={4} className={styles.leftColumn}>
            <div className={styles.courseHeader}>
              <h1 className={styles.courseTitle}>{courseDetail.title}</h1>
              <div className={styles.iconButtonGroup}>
                <button
                  className={styles.iconButton}
                  onClick={handleDownloadImage}
                  title="이미지 다운로드"
                >
                  <FaDownload />
                </button>
                <button
                  className={styles.iconButton}
                  onClick={handleDownloadPDF}
                  title="PDF 다운로드"
                >
                  <FaFilePdf />
                </button>
                <button
                  className={styles.iconButton}
                  onClick={handleKakaoShare}
                  title="카카오톡 공유"
                >
                  <FaShare style={{ fontSize: '1.5rem' }} />
                </button>
              </div>
            </div>
            <div id="spots-section">
              <CourseSpots
                spots={courseDetail.spots}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
              />
            </div>
          </Col>

          <Col md={8} className={styles.mapCol}>
            <div className={styles.mapContainer}>
              <MapView spots={combinedSpots} dayNumber={selectedDay} />
            </div>
            <div className={styles.reviewButtonGroup}>
              <button
                className={styles.reviewButton}
                onClick={handleReviewButtonClick}
              >
                리뷰 보러 가기
              </button>
              <button
                className={styles.writeReviewButton}
                onClick={() => navigate(`/course/${courseId}/create`)}
              >
                리뷰 작성하러 가기
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      {showModal && (
        <AlertModal
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export default CourseDetail;
