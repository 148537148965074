import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import styles from './DeleteAccount.module.css';
import deleteUser from '../../../services/api/auth/deleteUserSerice';

function DeleteAccount() {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDelete = async () => {
    if (!window.confirm('정말로 회원 탈퇴를 진행하시겠습니까?')) {
      return;
    }

    setIsProcessing(true); // 처리 중 상태 표시
    try {
      const response = await deleteUser();
      alert(response.retMessage || '회원 탈퇴가 처리되었습니다.');
      // 추가 작업: 로그아웃 처리 또는 리디렉션
      window.location.href = '/'; // 예: 홈 페이지로 이동
    } catch (error) {
      alert(error.retMessage || '회원 탈퇴에 실패하였습니다. 다시 시도해주세요.');
    } finally {
      setIsProcessing(false); // 처리 완료
    }
  };

  return (
    <div className={styles.deleteAccountContainer}>
      <h2 className={styles.title}>회원 탈퇴</h2>
      <Alert variant="danger" className={styles.alert}>
        회원 탈퇴를 진행하면 모든 정보가 삭제되며 복구가 불가능합니다. 신중히 선택하세요.
      </Alert>
      <Button
        variant="danger"
        onClick={handleDelete}
        className={styles.deleteButton}
        disabled={isProcessing} // 처리 중일 때 버튼 비활성화
      >
        {isProcessing ? '처리 중...' : '탈퇴하기'}
      </Button>
    </div>
  );
}

export default DeleteAccount;
