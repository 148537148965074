import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import './Chatbot.module.css';

function Chatbot({ botRecommendations = [], onAddPlace }) {
  // Define icons for each table
  const tableIcons = {
    restaurants: '🍴',
    accommodations: '🏨',
    attractions: '📍',
    botResponse: '🤖', // Default icon for bot response if table is undefined
  };

  // Safely flatten recommendations with error handling
  const flattenedRecommendations = React.useMemo(() => {
    // Check if botRecommendations is an array and has valid structure
    if (!Array.isArray(botRecommendations) || botRecommendations.length === 0) {
      return [];
    }

    return botRecommendations.flatMap((rec) => {
      // Ensure schedule is an array before mapping
      if (!rec.schedule || !Array.isArray(rec.schedule)) {
        return [];
      }

      return rec.schedule.map((item) => ({
        ...item,
        table: rec.table, // Use the table from the parent object
      }));
    });
  }, [botRecommendations]);

  const handleAddPlace = (place) => {
    if (!place) return;

    const placeData = {
      name: place.name,
      address: place.address,
      latitude: place.latitude,
      longitude: place.longitude,
      table: place.table,
      id: place.id || (Date.now() + Math.random()),
    };

    // Trigger onAddPlace callback to update the addedPlaces state in the parent
    onAddPlace(placeData);
    console.log('🟢 Added place:', placeData);
  };

  // If no recommendations, show a message
  if (flattenedRecommendations.length === 0) {
    return (
      <div className="chatbot">
        <h3>챗봇 추천</h3>
        <p>아직 추천된 장소가 없습니다.</p>
      </div>
    );
  }

  return (
    <div className="chatbot">
      <h3>챗봇 추천</h3>
      <div className="item-grid">
        {flattenedRecommendations.map((item) => (
          <Card key={item.id} className="plan-item">
            <Card.Body>
              <Dropdown>
                <Dropdown.Toggle as="div" id={`dropdown-${item.id}`} className="card-title">
                  {tableIcons[item.table] || '📍'} {item.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleAddPlace(item)}
                    className="dropdown-add-button"
                  >
                    추가
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Card.Text>📍 {item.address || '정보 없음'}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Chatbot;