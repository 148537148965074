import React, { useState } from 'react'; 
import { Button } from 'react-bootstrap';

function ReviewContent({ content }) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Early return if content is null or undefined
  if (!content) return null;

  const toggleContent = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      <p className={`review-content ${isExpanded ? 'expanded' : ''}`}>
        {isExpanded ? content : `${content.slice(0, 50)}${content.length > 50 ? '...' : ''}`}
      </p>

      {content.length > 50 && (
        <Button
          variant="link"
          size="sm"
          onClick={toggleContent}
          className="p-0 text-primary"
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: '0.8rem',
          }}
        >
          {isExpanded ? '접기' : '더보기'}
        </Button>
      )}
    </div>
  );
}

export default ReviewContent;