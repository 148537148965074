import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { FaPlayCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styles from './Main.module.css';
import CustomNavbar from '../../components/navbar/CustomNavbar';

function Main() {
  const [destination, setDestination] = useState('');
  const [duration, setDuration] = useState('');
  const [showDestinationModal, setShowDestinationModal] = useState(false);
  const navigate = useNavigate();

  const handleSelectDestination = (selectedDestination) => {
    setDestination(selectedDestination);
    setShowDestinationModal(false);
  };

  const handleSelectDuration = (selectedDuration) => {
    setDuration(selectedDuration);
  };

  const handleSubmit = () => {
    if (destination && duration) {
      navigate('/course', {
        state: { destination, duration: parseInt(duration, 10) },
      });
    } else {
      alert('여행지와 기간을 선택해주세요.');
    }
  };

  const destinations = [
    '서울',
    '부산',
    '대구',
    '인천',
    '광주',
    '대전',
    '울산',
    '세종',
    '경기',
    '강원',
    '충청북도',
    '충청남도',
    '전라북도',
    '전라남도',
    '경상북도',
    '경상남도',
    '제주',
  ];

  return (
    <>
      <CustomNavbar />
      <Container fluid className={`${styles.container}`}>
        <Row className={`${styles.mainRow}`}>
          <Col md={5} className={styles.selectionColumn}>
            <div className={styles.selectionBox}>
              <h2 className={styles.heading}>여행지를 선택하세요</h2>
              <p className={styles.subheading}>어디로 떠나고 싶으신가요?</p>
              <Button
                className={styles.selectButton}
                onClick={() => setShowDestinationModal(true)}
              >
                {destination ? destination.toUpperCase() : '여행지 선택'}
              </Button>
            </div>

            <div className={styles.selectionBox}>
              <h2 className={styles.heading}>기간을 선택하세요</h2>
              <p className={styles.subheading}>며칠간 여행하고 싶으신가요?</p>
              <Row className="gy-3">
                {['1일', '2일', '3일', '4일', '5일'].map((day, index) => (
                  <Col xs={4} key={day}>
                    <Button
                      variant="outline-primary"
                      className={`${styles.durationButton} ${
                        duration === (index + 1).toString() ? styles.selected : ''
                      }`}
                      onClick={() => handleSelectDuration((index + 1).toString())}
                    >
                      {day}
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>

          <Col md={3} className={`${styles.iconColumn}`}>
            <FaPlayCircle
              className={styles.iconButton}
              onClick={handleSubmit}
              style={{
                cursor: destination && duration ? 'pointer' : 'not-allowed',
                opacity: destination && duration ? 1 : 0.5,
              }}
            />
            <p className={styles.buttonLabel}>코스 생성하러 가기</p>
          </Col>
        </Row>
      </Container>

      <Modal
        show={showDestinationModal}
        onHide={() => setShowDestinationModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>여행지 선택</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="gy-3">
            {destinations.map((location) => (
              <Col xs={6} md={4} key={location}>
                <Card className={styles.locationCard}>
                  <Card.Body
                    className={styles.cardBody}
                    onClick={() => handleSelectDestination(location)}
                  >
                    {location}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Main;
