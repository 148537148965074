import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './CourseSpots.module.css';

function CourseSpots({ courseTitle, spots, selectedDay, setSelectedDay }) {
  const getDayColor = (dayNumber) => {
    const colors = [
      { circle: '#cc00ff', line: '#cc00ff' },
      { circle: '#0000FF', line: '#0000FF' },
      { circle: '#00FF00', line: '#00FF00' },
      { circle: '#FFA500', line: '#FFA500' },
      { circle: '#b700ff', line: '#b700ff' },
    ];
    return colors[(dayNumber - 1) % colors.length];
  };

  const renderDaySpots = (day) => {
    const dayColor = getDayColor(day.dayNumber);

    const allSpots = [
      ...day.accommodations.map((spot) => ({ ...spot, type: 'accommodations' })),
      ...day.restaurants.map((spot) => ({ ...spot, type: 'restaurants' })),
      ...day.touristAttractions.map((spot) => ({
        ...spot,
        type: 'touristAttractions',
      })),
    ];

    return (
      <div key={`day-${day.dayNumber}`} className={styles.daySection}>
        <h6
          className={`${styles.dayTitle} ${
            selectedDay === day.dayNumber ? styles.activeTitle : ''
          }`}
          onClick={() => setSelectedDay(day.dayNumber)}
          style={{ cursor: 'pointer' }}
        >
          Day {day.dayNumber}
        </h6>
        {allSpots.map((spot, index) => {
          let icon = '';
          let backgroundColor = '';

          if (spot.type === 'accommodations') {
            icon = '🏠';
            backgroundColor = '#efe8f5';
          } else if (spot.type === 'restaurants') {
            icon = '🍚';
            backgroundColor = '#fff3e0';
          } else if (spot.type === 'touristAttractions') {
            icon = '📍';
            backgroundColor = '#e3f2fd';
          }

          return (
            <Row
              key={`${day.dayNumber}-${spot.id}-${index}`}
              className={styles.itemRow}
            >
              <Col xs={2} className={styles.circleCol}>
                <div
                  className={styles.timelineCircle}
                  style={{ '--circle-color': dayColor.circle }}
                >
                  {index + 1}
                </div>
                {index < allSpots.length - 1 && (
                  <div
                    className={styles.timelineLine}
                    style={{ backgroundColor: dayColor.line }}
                  />
                )}
              </Col>
              <Col xs={10} className={styles.contentCol}>
                <div
                  className={styles.timelineContent}
                  style={{ backgroundColor }}
                >
                  <span className={styles.icon}>{icon}</span>
                  {spot.title}
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.leftColumn}>
      <h1 className={styles.pageTitle}>{courseTitle}</h1>
      {spots.map((day) => renderDaySpots(day))}
    </div>
  );
}

export default CourseSpots;
