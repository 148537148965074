import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import PaginationUtil from '../../../components/pagination/PaginationUtil';
import CardItem from '../../../components/card/CardItem';
import { getUserCourses } from '../../../services/api/course/CourseListService';
import './MyCourseList.css';

function MyCourseList() {
  const [userCourses, setUserCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchUserCourses = async () => {
      setLoading(true);
      try {
        const { data, totalItems } = await getUserCourses(currentPage, itemsPerPage);
        setUserCourses(data);
        setTotalItems(totalItems);
      } catch (error) {
        console.error('❌ Error fetching user courses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCourses();
  }, [currentPage]);

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <>
      <div className="user-courses-container">
        <h2 className="user-courses-title">내 여행 코스</h2>
        <Container>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <Row>
                {userCourses.map((course) => (
                  <Col key={course.id} lg={3} md={4} sm={6} xs={12} className="mb-4">
                    <CardItem
                      title={course.title}
                      description=""
                      metaRight={`⭐ ${course.starAvg || '0.0'}`}
                      imageUrl={course.imageUrl}
                      showBookmark={false} // 개인 코스라 북마크 표시 제외
                      onClick={() => handleCardClick(course.id)}
                    />
                  </Col>
                ))}
              </Row>

              <div className="pagination-container">
                <PaginationUtil
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  currentPage={currentPage + 1}
                  onPageChange={(page) => setCurrentPage(page - 1)}
                />
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default MyCourseList;
