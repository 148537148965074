import axiosInstance from '../axiosInstance';

// 댓글 목록 조회
export const getComments = async (recordId, page, size = 5) => {
  try {
    const response = await axiosInstance.get(`/api/v1/posts/${recordId}/comments`, {
      params: {
        page,
        size,
      },
    });

    // 중첩된 data에서 댓글 목록과 totalPages를 추출
    const comments = response.data.data.data;
    const totalPages = response.data.data.totalPages;

    return { comments, totalPages };
  } catch (error) {
    console.error('Error fetching comments:', error.response || error);
    throw error;
  }
};


// 댓글 생성
export const addComment = async (postId, content, parentCommentId = null) => {
  try {
    const response = await axiosInstance.post(`/api/v1/posts/${postId}/comments`, {
      content,
      parentCommentId,
    });
    return response.data.data; // 생성된 댓글 반환
  } catch (error) {
    console.error('Failed to add comment:', error);
    throw error; // 에러 재전달
  }
};

// 댓글 수정
export const updateComment = async (postId, commentId, content) => {
  try {
    const response = await axiosInstance.patch(`/api/v1/posts/${postId}/comments/${commentId}`, {
      content,
    });
    return response.data.data; // 수정된 댓글 반환
  } catch (error) {
    console.error('Failed to update comment:', error);
    throw error; // 에러 재전달
  }
};

// 댓글 삭제
export const deleteComment = async (postId, commentId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/posts/${postId}/comments/${commentId}`);
    return response.data; // 삭제 결과 반환
  } catch (error) {
    console.error('Failed to delete comment:', error);
    throw error; // 에러 재전달
  }
};

// 댓글 대댓글 생성 함수 추가
export const addReply = async (postId, content, parentCommentId) => {
  try {
    const response = await axiosInstance.post(`/api/v1/posts/${postId}/comments`, {
      content,
      parentCommentId,
    });
    return response.data.data; // Return the created reply
  } catch (error) {
    console.error('Failed to add reply:', error);
    throw error; // Re-throw the error
  }
};

