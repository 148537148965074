// src/services/api/record/RecordListService.js
import axiosInstance from '../axiosInstance';

// 여행 기록 데이터를 페치하는 메서드
export const fetchTravelRecords = async (page, size) => {
  try {
    const response = await axiosInstance.get('/api/v1/posts', {
      params: { page, size },
    });

    if (response.data.retCode === '00') {
      const { data } = response.data.data; // Navigate to nested `data` field
      const totalItems = response.data.data.totalItems; // Extract total items

      // Blob URL 변환 없이 반환된 imageUrl 사용
      const records = data.map((record) => ({
        ...record,
        imageUrl: record.imageUrl || 'https://via.placeholder.com/300', // 기본 이미지 설정
      }));

      return { records, totalItems };
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('Error fetching travel records:', error.response || error);
    throw error;
  }
};

// 이미지 로딩 메서드 (사용하지 않을 경우 제거 가능)
export const loadImage = async (imagePath) => {
  try {
    const response = await axiosInstance.get(imagePath, {
      responseType: 'blob', // 이미지를 Blob으로 받음
    });
    return URL.createObjectURL(response.data); // Blob URL 생성
  } catch (error) {
    console.error('Error loading image:', error);
    throw error;
  }
};

export const fetchUserRecords = async (page = 0, size = 10) => {
  try {
    const response = await axiosInstance.get('/api/v1/users/posts', {
      params: { page, size },
    });

    if (response.data.retCode === '00') {
      const { data } = response.data.data;
      const totalItems = response.data.data.totalItems;

      const records = data.map((record) => ({
        ...record,
        imageUrl: record.imageUrl || 'https://via.placeholder.com/300',
      }));

      return { records, totalItems };
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('❌ Error fetching user records:', error);
    throw error;
  }
};
