import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Image, Alert } from 'react-bootstrap';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import ReactStars from 'react-rating-stars-component';
import { useNavigate } from 'react-router-dom'; // React Router 사용
import { submitCourseReview } from '../../services/api/review/ReviewService';

function ReviewCreate() {
  const [reviewText, setReviewText] = useState('');
  const [images, setImages] = useState([]);
  const [rating, setRating] = useState(0);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const courseId = 5; // Example course ID; replace with dynamic value if needed
  const token = 'your-bearer-token'; // Replace with dynamic token retrieval logic

  const navigate = useNavigate(); // 페이지 이동 함수

  const handleTextChange = (e) => {
    setReviewText(e.target.value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > 3) {
      alert('최대 3개의 이미지만 업로드할 수 있습니다.');
      return;
    }
    setImages((prevImages) => [...prevImages, ...files.slice(0, 3 - prevImages.length)]);
  };

  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const createRequest = {
      content: reviewText,
      stars: rating,
    };

    const response = await submitCourseReview(token, courseId, createRequest, images);
    if (response.retCode === '00') {
      // 성공 시 페이지 이동
      navigate(`/course/${courseId}/reviews`);
    } else {
      setErrorMessage(response.retMessage || '리뷰 제출에 실패했습니다.');
    }
  };

  return (
    <>
      <CustomNavbar />
      <Container className="review-container mt-5">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            {successMessage && (
              <Alert variant="success" onClose={() => setSuccessMessage(false)} dismissible>
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
                {errorMessage}
              </Alert>
            )}

            

            <h2 className="text-center mb-4 review-title">리뷰 작성</h2>

            <Form onSubmit={handleSubmit} className="p-4 shadow-sm rounded bg-light">
              <Form.Group controlId="reviewText" className="mb-4">
                <Form.Label>리뷰 내용</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="리뷰 내용을 작성해주세요."
                  value={reviewText}
                  onChange={handleTextChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="reviewRating" className="mb-4">
                <Form.Label>별점</Form.Label>
                <ReactStars
                  count={5}
                  value={rating}
                  onChange={handleRatingChange}
                  size={30}
                  isHalf={true}
                  activeColor="#ffd700"
                />
              </Form.Group>

              <Form.Group controlId="reviewImages" className="mb-4">
                <Form.Label>이미지 업로드 (최대 3장)</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageUpload}
                />
                <Row className="mt-3">
                  {images.map((image, index) => (
                    <Col key={index} xs={4} className="text-center">
                      <Image
                        src={URL.createObjectURL(image)}
                        thumbnail
                        alt={`업로드된 이미지 ${index + 1}`}
                        className="mb-2"
                        style={{ maxHeight: '150px', objectFit: 'cover' }}
                      />
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => removeImage(index)}
                      >
                        삭제
                      </Button>
                    </Col>
                  ))}
                </Row>
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                리뷰 제출
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReviewCreate;
