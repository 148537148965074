import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAuth } from '../../context/AuthContext'; // 로그인 상태 관리 훅
import { Button, Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { FaUserCircle, FaHeart, FaPlane } from 'react-icons/fa';
import styles from './CustomNavbar.module.css'; 

function CustomNavbar() {
  const navigate = useNavigate(); // 페이지 이동을 위한 navigate 훅
  const { isAuthenticated, logout } = useAuth(); // 로그인 상태와 로그아웃 함수 가져오기

  // 로그아웃 처리 함수
  const handleLogout = () => {
    logout(); // 인증 상태를 false로 설정하고, 쿠키에서 토큰 삭제
    navigate('/login'); // 로그인 페이지로 리다이렉트
  };

  return (
    <Navbar expand="lg" className={`${styles.navbarContainer} d-flex`}>
      <Container fluid className="d-flex justify-content-between align-items-center">
        {/* 좌측: 로고와 네비게이션 메뉴 */}
        <div className="d-flex align-items-center">
          <Navbar.Brand onClick={() => navigate('/')} className={styles.brand}>
            SOLLAIM
          </Navbar.Brand>
          <Nav className="d-flex align-items-center ms-3">
            <Nav.Link onClick={() => navigate('/main')} className={styles.navLink}>
              코스 생성
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/courses')} className={styles.navLink}>
              여행 코스
            </Nav.Link>
            <Nav.Link onClick={() => navigate('/records')} className={styles.navLink}>
              여행 기록
            </Nav.Link>
          </Nav>
        </div>

        {/* 우측: 아이콘과 버튼 */}
        <div className="d-flex align-items-center gap-3">
          <Nav.Link onClick={() => navigate('/mypage')} className="d-flex align-items-center">
            <FaUserCircle size={24} className={styles.icon} />
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/bookmark')} className="d-flex align-items-center">
            <FaHeart size={24} className={styles.icon} />
          </Nav.Link>
          {isAuthenticated ? (
            <Button onClick={handleLogout} className={styles.navButton}>
              로그아웃
            </Button>
          ) : (
            <Button onClick={() => navigate('/login')} className={styles.navButton}>
              로그인
            </Button>
          )}
        </div>
      </Container>
    </Navbar>




  );
}

export default CustomNavbar;