import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { loginApi } from '../../utils/apiUtils';
import { useAuth } from '../../context/AuthContext';
import { setCookie } from '../../utils/cookieUtils';
import styles from './Login.module.css';

function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const result = await loginApi(email, password);

      // retCode에 따라 처리
      switch (result.retCode) {
        case '00':
          setCookie('accessToken', result.data.accessToken);
          setCookie('refreshToken', result.data.refreshToken);
          login(); // 인증 상태 업데이트
          navigate('/main', { replace: true });
          break;
        case '03':
          setError('비밀번호가 틀렸습니다.');
          break;
        case '04':
          setError('존재하지 않는 이메일입니다.');
          break;
        case '99':
          setError('서버 오류로 인해 로그인에 실패하였습니다.');
          break;
        default:
          setError(result.retMessage || '알 수 없는 오류가 발생했습니다.');
          break;
      }
    } catch (err) {
      console.error('로그인 오류:', err);
      setError('로그인에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const handleSignUpRedirect = () => {
    navigate('/signup');
  };

  return (
    <Card className={`shadow ${styles.loginCard}`}>
      <Card.Body>
        <h3 className="text-center mb-3">로그인하세요</h3>
        <p className="text-center text-muted mb-4">손쉽게 여행 플랜을 짜보아요</p>
        {error && <p className="text-danger text-center">{error}</p>}

        <Form onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>이메일</Form.Label>
            <Form.Control
              type="email"
              placeholder="이메일을 입력하세요"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="비밀번호를 입력하세요"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>


          <Button variant="primary" type="submit" className="w-100">
            로그인
          </Button>
        </Form>

        <div className="text-center mt-3">
          <span className="text-muted">SOLLAIM이 처음이세요? </span>
          <Button variant="link" className={styles.signUpButton} onClick={handleSignUpRedirect}>
            회원가입
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default LoginForm;
