import axiosInstance from '../axiosInstance';

// 이메일 중복 확인 API 호출
export const checkEmailDuplication = async (email) => {
    const response = await axiosInstance.post('/api/v1/auth/check/email', { email });
    return response;
  };
  

// 회원가입
export const signup = async (formData) => {
  const response = await axiosInstance.post('/api/v1/auth/signup', formData);
  return response.data;
};


  
  // 리프레시 토큰을 사용하여 새로운 액세스 토큰을 발급받는 함수
  export const refreshTokenApi = async (refreshToken) => {
    try {
      const response = await axiosInstance.post('/api/v1/auth/refresh-token', { refreshToken });
      return response.data; // 새로운 액세스 토큰 반환
    } catch (error) {
      console.error('❌ 리프레시 토큰 요청 실패:', error);
      throw new Error('리프레시 토큰 만료');
    }
  };
  