// src/services/api/axiosInstance.js
import axios from 'axios';
import { getCookie, setCookie } from '../../utils/cookieUtils'; // 쿠키 관련 유틸리티 함수

const axiosInstance = axios.create({
  baseURL: 'https://solaim.site/api', // 백엔드 서버 주소
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터
axiosInstance.interceptors.request.use(
  (config) => {
    // 액세스 토큰을 Authorization 헤더에 추가
    const accessToken = getCookie('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    console.log('📤 Request:', config);
    return config;
  },
  (error) => {
    console.error('❌ Request Error:', error);
    return Promise.reject(error);
  }
);

// 응답 인터셉터
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('📥 Response:', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // 액세스 토큰 만료 시 리프레시 토큰을 사용해 새로운 액세스 토큰을 받음
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // 리프레시 토큰을 쿠키에서 가져옴
      const refreshToken = getCookie('refreshToken');
      if (refreshToken) {
        try {
          // 리프레시 토큰을 사용해 새로운 액세스 토큰을 발급받음
          const response = await axios.post('https://solaim.site/api/v1/auth/refresh', {
            refreshToken: refreshToken,
          });

          const newAccessToken = response.data.accessToken;

          // 새로운 액세스 토큰을 쿠키에 저장
          setCookie('accessToken', newAccessToken);

          // 원래의 요청에 새로운 액세스 토큰을 추가하고 다시 요청
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (err) {
          console.error('❌ Refresh Token Error:', err);
          // 리프레시 토큰이 유효하지 않으면 로그아웃 처리
          // 예: 삭제 쿠키, 로그인 페이지로 리디렉션
        }
      }
    }

    console.error('❌ Response Error:', error.response || error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
