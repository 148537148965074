import React from 'react';
import LoginForm from './LoginForm';
import styles from './Login.module.css';

function Login() {
  return (
    <div className={styles.loginContainer}>
      {/* 상단 로고 */}
      <div className={styles.logo}>SOLLAIM</div>

      {/* 로그인 폼 */}
      <div className={styles.loginFormWrapper}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
