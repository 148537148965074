import axiosInstance from '../axiosInstance';

/**
 * 게시물 생성 요청
 * @param {Number} courseId - 게시물이 속한 코스 ID
 * @param {Object} createRequest - 게시물 요청 정보 (title, content 포함)
 * @param {Array<File>} images - 업로드할 이미지 파일 배열
 * @returns {Promise<Object>} 생성된 게시물 정보
 */
export const createRecordPost = async (courseId, createRequest, images = []) => {
    const formData = new FormData();
  
    // createRequest를 Blob으로 변환하여 명시적으로 Content-Type 설정
    const createRequestBlob = new Blob(
      [JSON.stringify(createRequest)], 
      { type: 'application/json' } // Content-Type을 application/json으로 설정
    );
    formData.append('createRequest', createRequestBlob);
  
    // 이미지 파일 추가
    images.forEach((image) => {
      formData.append('images', image);
    });
  
    try {
      const response = await axiosInstance.post(`/api/v1/course/${courseId}/post`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // FormData 요청에 맞는 Content-Type
        },
      });
      return response.data; // 성공 시 응답 데이터 반환
    } catch (error) {
      console.error('❌ Failed to create record post:', error);
      throw error; // 호출한 곳에서 처리할 수 있도록 예외 던짐
    }
  };
  
