// src/utils/validationUtils.js

/**
 * 이메일 형식 검사
 * @param {string} email - 입력된 이메일
 * @returns {boolean} - 유효하면 true, 아니면 false
 */
export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  /**
   * 비밀번호 형식 검사
   * @param {string} password - 입력된 비밀번호
   * @returns {boolean} - 유효하면 true, 아니면 false
   */
  export const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    return passwordRegex.test(password);
  };