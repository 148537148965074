import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import SidebarMenu from './SidebarMenu';
// import MyReviews from './MyReviews';
import DeleteAccount from './deleteUser/DeleteAccount';
import MyCourseList from './course/MyCourseList';
import MyRecordList from './record/MyRecordList';
import MyReview from './review/MyReview';
import UserInfo from './info/UserInfo';

function MyPage() {
  const [selectedMenu, setSelectedMenu] = useState('내 여행');

  const renderContent = () => {
    switch (selectedMenu) {
      case '회원정보 수정':
        return <UserInfo />;
      case '내 여행':
        return <MyCourseList />;
      case '내 여행 기록':
        return <MyRecordList />;
      case '내 리뷰':
        return <MyReview />;
      case '회원 탈퇴':
        return <DeleteAccount />;
      default:
        return <div>선택된 메뉴가 없습니다.</div>;
    }
  };

  return (
    <>
      <CustomNavbar />
      <Container fluid>
        <Row>
          <Col md={2} className="p-0">
            <SidebarMenu setSelectedMenu={setSelectedMenu} />
          </Col>
          <Col md={10} className="bg-white p-4">
            {renderContent()}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyPage;
