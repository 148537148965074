// src/pages/course/map/MapPage.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from '../../../../components/navbar/CustomNavbar';
import MapView from '../../../map/MapView'; // MapView 임포트
import styles from './MapPage.module.css';

function MapPage() {
  return (
    <>
      <CustomNavbar />
      <Container fluid className={styles.pageContainer}>
        <Row className={styles.fullHeightRow}>
          {/* Left Column */}
          <Col md={3} className={styles.leftColumn}>
            <h5>Day별 일정</h5>
            <p>Day별 장소와 정보를 여기에 추가할 수 있습니다.</p>
          </Col>

          {/* Center Column */}
          <Col md={9} className={styles.centerColumn}>
            <MapView /> {/* MapView 삽입 */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MapPage;
