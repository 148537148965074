import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import './EmailField.css'; // 추가된 CSS 파일

function EmailField({ value, onChange, onCheck, message, isValid }) {
  return (
    <div className="email-field-container"> {/* flexbox 컨테이너 */}
      <Form.Group className="form-group" controlId="formEmail">
        <Form.Label>이메일</Form.Label>
        <InputGroup>
          <Form.Control
            type="email"
            placeholder="email@example.com"
            name="email"
            value={value}
            onChange={onChange}
            required
          />
          <Button variant="secondary" onClick={onCheck}>
            중복 확인
          </Button>
        </InputGroup>
      </Form.Group>
      {/* 메시지 영역 */}
      <div
        className={`email-message ${isValid ? 'text-success' : 'text-danger'}`}
      >
        {message}
      </div>
    </div>
  );
}

export default EmailField;
