import axiosInstance from '../../../services/api/axiosInstance';

/**
 * 코스 상세 정보를 가져옵니다.
 * @param {number} courseId - 조회할 코스 ID
 * @returns {Promise<Object>} 코스 상세 데이터 반환
 * @throws {Error} 요청 실패 시 에러를 반환
 */
export const getCourseDetails = async (courseId) => {
  try {
    const response = await axiosInstance.get(`/api/v1/course/${courseId}`);

    // retCode 확인 및 데이터 반환
    if (response.data.retCode !== '00') {
      throw new Error(response.data.retMessage || 'Failed to fetch course details.');
    }

    return response.data.data; // CourseResponseForDetail 데이터 반환
  } catch (error) {
    console.error('Error fetching course details:', error);
    throw new Error(error.response?.data?.retMessage || 'Failed to fetch course details.');
  }
};
