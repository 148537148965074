import React, { useEffect, useState } from 'react';
import './UserInfo.css';
import updateUserInfo from '../../../services/api/auth/UserInfoUpdateService';
import getUserInfo from '../../../services/api/auth/UserInfoService';

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    createdAt: '',
  });
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  // 사용자 정보를 가져오는 함수
  const fetchUserInfo = async () => {
    try {
      const response = await getUserInfo();
      setUserInfo(response);
      setName(response.name);
    } catch (error) {
      setMessage(`❌ ${error.response?.data?.retMessage || '유저 정보를 가져오는데 실패했습니다.'}`);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateUserInfo({ name, password });
      setMessage(`✅ ${response.retMessage}`);
      fetchUserInfo(); // 수정 후 최신 정보 반영
    } catch (error) {
      setMessage(`❌ ${error.response?.data?.retMessage || '오류가 발생했습니다.'}`);
    }
  };

  return (
    <div className="user-info-wrapper">
      <div className="user-info-card">
        <h2 className="card-title">회원정보</h2>
        <div className="card-content">
          <p><strong>이름:</strong> {userInfo.name}</p>
          <p><strong>이메일:</strong> {userInfo.email}</p>
          <p><strong>가입일:</strong> {userInfo.createdAt}</p>
        </div>
      </div>

      <form className="update-form" onSubmit={handleSubmit}>
        <h3 className="form-title">정보 수정</h3>
        <div className="form-group">
          <label htmlFor="name">이름</label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="이름을 입력하세요"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">비밀번호</label>
          <input
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="새 비밀번호를 입력하세요"
          />
        </div>
        <button type="submit" className="update-button">수정하기</button>
      </form>

      {message && <p className="update-message">{message}</p>}
    </div>
  );
};

export default UserInfo;
