import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import PaginationUtil from '../../../components/pagination/PaginationUtil';
import CardItem from '../../../components/card/CardItem';
import { getTravelCourses } from '../../../services/api/course/CourseListService';
import { createBookmark, deleteBookmark } from '../../../services/api/bookmark/bookmarkService';
import './CourseList.css';

function CourseList() {
  const [travelCourses, setTravelCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchTravelCourses = async () => {
      setLoading(true);
      try {
        const { data, totalItems } = await getTravelCourses(currentPage, itemsPerPage);
        setTravelCourses(data);
        setTotalItems(totalItems);
      } catch (error) {
        console.error('❌ Error fetching travel courses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTravelCourses();
  }, [currentPage]);

  const handleBookmarkToggle = async (courseId) => {
    const course = travelCourses.find((course) => course.id === courseId);
    if (!course) return;

    try {
      let updatedCourse;
      if (course.isBookmarked) {
        await deleteBookmark(courseId);
        updatedCourse = { ...course, isBookmarked: false };
      } else {
        await createBookmark(courseId);
        updatedCourse = { ...course, isBookmarked: true };
      }

      setTravelCourses((prevCourses) =>
        prevCourses.map((c) => (c.id === courseId ? updatedCourse : c))
      );
    } catch (error) {
      console.error('❌ Error toggling bookmark:', error);
    }
  };

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <>
      <CustomNavbar />
      <div className="travel-courses-container">
      <Button
        variant="primary"
        className="create-course-button"
        onClick={() => navigate('/main')}
      >
        여행 코스 생성하러 가기
      </Button>
        <h2 className="travel-courses-title">여행 코스</h2>
        <Container>
          
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <Row>
                {travelCourses.map((course) => (
                  <Col key={course.id} lg={3} md={4} sm={6} xs={12} className="mb-4">
                    <CardItem
                      title={course.title}
                      description=""
                      metaRight={`⭐ ${course.starAvg || '0.0'}`}
                      imageUrl={course.imageUrl}
                      showBookmark={true}
                      isBookmarked={course.isBookmarked}
                      onBookmarkToggle={() => handleBookmarkToggle(course.id)}
                      onClick={() => handleCardClick(course.id)}
                    />
                  </Col>
                ))}
              </Row>

              <div className="pagination-container">
                <PaginationUtil
                  totalPages={Math.ceil(totalItems / itemsPerPage)}
                  currentPage={currentPage + 1}
                  onPageChange={(page) => setCurrentPage(page - 1)}
                />
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default CourseList;
