import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import PaginationUtil from '../../../components/pagination/PaginationUtil';
import CardItem from '../../../components/card/CardItem';
import { fetchUserBookmarks, deleteBookmark } from '../../../services/api/bookmark/bookmarkService';
import './MyBookmark.css';

function MyBookmark() {
  const [bookmarkedCourses, setBookmarkedCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [removingCourseId, setRemovingCourseId] = useState(null);
  const itemsPerPage = 12;
  const navigate = useNavigate();

  useEffect(() => {
    const loadBookmarks = async () => {
      setLoading(true);
      setError(null);
      try {
        const { bookmarks, totalItems } = await fetchUserBookmarks(currentPage, itemsPerPage);
        setBookmarkedCourses(bookmarks);
        setTotalItems(totalItems);
      } catch (err) {
        console.error('❌ Error loading bookmarks:', err);
        setError('북마크를 불러오는 데 실패했습니다.');
      } finally {
        setLoading(false);
      }
    };

    loadBookmarks();
  }, [currentPage]);

  const handleBookmarkRemove = async (courseId) => {
    try {
      setRemovingCourseId(courseId); // 삭제 중인 courseId 설정
      setError(null); // 에러 초기화
      await deleteBookmark(courseId);

      // 삭제 성공 시 상태 업데이트
      setBookmarkedCourses((prevCourses) =>
        prevCourses.filter((course) => course.courseId !== courseId)
      );
    } catch (err) {
      console.error('❌ Error removing bookmark:', err);
      setError('북마크 삭제에 실패했습니다.');
    } finally {
      setRemovingCourseId(null); // 삭제 완료 후 초기화
    }
  };

  const handleCardClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
          <Spinner animation="border" />
        </div>
      );
    }

    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }

    if (bookmarkedCourses.length === 0) {
      return <p className="no-bookmarks-message">저장된 북마크가 없습니다.</p>;
    }

    return (
      <Row>
        {bookmarkedCourses.map((course) => (
          <Col key={course.courseId} lg={3} md={4} sm={6} xs={12} className="mb-4">
            <CardItem
              title={course.courseTitle}
              description=""
              metaRight={`⭐ ${course.courseStarAvg}`}
              imageUrl={course.courseImageUrl}
              showBookmark={true}
              isBookmarked={true}
              isLoading={removingCourseId === course.courseId} // 삭제 중인지 확인
              onBookmarkToggle={() => handleBookmarkRemove(course.courseId)} // courseId 전달
              onClick={() => handleCardClick(course.courseId)}
            />
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <>
      <CustomNavbar />
      <div className="bookmarked-courses-container">
        <h2 className="bookmarked-courses-title">내 북마크</h2>
        <Container>
          {renderContent()}
          <div className="pagination-container">
            <PaginationUtil
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              currentPage={currentPage + 1}
              onPageChange={(page) => setCurrentPage(page - 1)}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default MyBookmark;
