// src/services/api/record/RecordDetailService.js
import axiosInstance from '../axiosInstance';

export const getPostDetail = async (postId) => {
  try {
    const response = await axiosInstance.get(`/api/v1/posts/${postId}`);
    if (response.data.retCode === '00') {
      return response.data.data;
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('Error fetching post details:', error);
    throw error;
  }
};

// 이미지 로딩을 위한 새로운 메서드 추가
export const loadImage = async (imagePath) => {
  try {
    const response = await axiosInstance.get(imagePath, {
      responseType: 'blob', // 이미지를 Blob으로 받음
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error loading image:', error);
    throw error;
  }
};

// 게시물 삭제 메서드 추가
export const deletePost = async (postId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/posts/${postId}`);
    if (response.data.retCode === '00') {
      console.log('Post deleted successfully:', response.data.retMessage);
      return response.data;
    } else {
      throw new Error(response.data.retMessage);
    }
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};
