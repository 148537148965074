// src/services/CourseCreateService.js
import axiosInstance from '../axiosInstance';

/**
 * 추천 관광지를 가져오는 함수
 * @param {string} destination - 추천을 위한 목적지
 * @returns {Promise<Object>} - 성공 시 추천 관광지 목록을 포함한 데이터 또는 에러 메시지
 */
export const fetchRecommendedSpots = async (destination) => {
  try {
    const response = await axiosInstance.post('/api/v1/ai/recommend/spot', {
      location: destination,
    });

    const data = response.data;

    if (data.retCode === '00') {
      return { success: true, data: data.data.recommendations };
    } else {
      return { success: false, error: data.retMessage || '추천 관광지를 불러오는 중 오류가 발생했습니다.' };
    }
  } catch (error) {
    console.error('❌ Error fetching recommended spots:', error);
    return {
      success: false,
      error: error.response?.data?.message || '서버와 통신 중 오류가 발생했습니다.',
    };
  }
};

/**
 * 특정 관광지의 상세 정보를 가져오는 함수
 * @param {number} spotId - 조회할 관광지 ID
 * @returns {Promise<Object>} - 성공 시 관광지 세부 정보를 포함한 데이터 또는 에러 메시지
 */
export const fetchSpotDetails = async (spotId) => {
    try {
      const response = await axiosInstance.get(`/api/v1/ai/recommend/spot/${spotId}`);
      const data = response.data;
  
      if (data.retCode === '00') {
        return { success: true, data: data.data.recommendation };
      } else {
        return { success: false, error: data.retMessage || '관광지 정보를 불러오는 중 오류가 발생했습니다.' };
      }
    } catch (error) {
      console.error('❌ Error fetching spot details:', error);
      return {
        success: false,
        error: error.response?.data?.message || '서버와 통신 중 오류가 발생했습니다.',
      };
    }
  };
/**
 * 챗봇 세션을 시작하는 함수
 * @param {string} location - 여행 장소
 * @param {number} days - 여행 기간
 * @returns {Promise<Object>} - 성공 시 세션 ID 및 응답 메시지, 실패 시 에러 메시지
 */
export const startChatbotSession = async (location, days) => {
    try {
      console.log('📤 Sending request to start chatbot session:', { location, days });
      const response = await axiosInstance.post('/api/v1/ai/chatbot/start', {
        location,
        days,
      });
      console.log('📥 Received response for start chatbot session:', response.data);
  
      // 성공적인 응답 반환
      return {
        success: true,
        data: response.data.data, // data가 포함된 구조 반환
      };
    } catch (error) {
      console.error('❌ Error in startChatbotSession:', error);
  
      // 실패 시 에러 반환
      return {
        success: false,
        error: error.response?.data?.message || '챗봇 세션 시작 중 오류가 발생했습니다.',
      };
    }
  };
  
  
  
  /**
   * 챗봇과 대화하는 함수
   * @param {string} sessionId - 세션 ID
   * @param {string} message - 사용자 메시지
   * @returns {Promise<Object>} - 성공 시 봇 응답 메시지, 실패 시 에러 메시지
   */
  export const sendMessageToChatbot = async (sessionId, message) => {
    try {
      const response = await axiosInstance.post('/api/v1/ai/chatbot/dialog', {
        session_id: sessionId,
        message,
      });
      const data = response.data;
  
      if (data.retCode === '00') {
        return { success: true, data: data.data };
      } else {
        return { success: false, error: data.retMessage };
      }
    } catch (error) {
      console.error('❌ Error sending message to chatbot:', error);
      return {
        success: false,
        error: error.response?.data?.message || '서버와 통신 중 오류가 발생했습니다.',
      };
    }
  };

  export const createPlanner = async (location, days) => {
    try {
      const response = await axiosInstance.post('/api/v1/ai/planner', {
        location,
        days,
      });
      return response.data.retCode === '00'
        ? { success: true, data: response.data.data }
        : { success: false, error: response.data.retMessage };
    } catch (error) {
      return { success: false, error: '일정 생성 실패' };
    }
  };
  

  export const fetchPlannerSpotDetails = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/v1/ai/planner/${id}`);
      return response.data.retCode === '00'
        ? { success: true, data: response.data.data }
        : { success: false, error: response.data.retMessage };
    } catch (error) {
      return { success: false, error: '상세 정보 조회 실패' };
    }
  };
  
  
  
  
  
/**
 * 코스 생성 요청
 * @param {string} token - 인증 토큰
 * @param {string} title - 코스 제목
 * @param {boolean} isPrivate - 코스 공개 여부
 * @param {Array} addedPlaces - 추가된 장소 데이터
 * @param {File|null} image - 업로드할 이미지 파일 (선택 사항)
 * @returns {Promise} - 서버 응답
 */
export const createCourseWithMultipart = async (token, createRequest) => {
  // FormData 생성
  const formData = new FormData();

  // JSON 데이터를 Blob으로 추가
  formData.append(
    'createRequest',
    new Blob([JSON.stringify(createRequest)], { type: 'application/json' })
  );

  // 디버깅: createRequest 내용 확인
  console.log('📦 Payload (createRequest):', createRequest);

  // 디버깅: FormData 내용 확인
  for (const [key, value] of formData.entries()) {
    console.log(`📂 FormData Key: "${key}", Value:`, value);
  }

  try {
    // Axios 요청
    const response = await axiosInstance.post('/api/v1/course', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`, // 인증 헤더
      },
    });

    // 상태 코드 확인 및 데이터 반환
    if (response.status === 200 || response.status === 201) {
      console.log('✅ Course created successfully:', response.data);
      return response.data;
    } else {
      console.error('⚠️ Unexpected response status:', response.status);
      console.error('⚠️ Response data:', response.data);
      return { retCode: '99', retMessage: 'Unexpected response status' };
    }
  } catch (error) {
    // 에러 처리
    if (error.response) {
      console.error('❌ API Error Response:', error.response.data);
      console.error('❌ API Error Status:', error.response.status);
    } else {
      console.error('❌ Network or Unexpected Error:', error.message);
    }

    return { retCode: '99', retMessage: 'Failed to create course' };
  }
};

