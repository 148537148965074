import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getPostDetail, loadImage, deletePost } from '../../services/api/record/RecordDetailService';
import { useAuth } from '../../context/AuthContext';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import CourseSpots from '../course/get/CourseSpots';
import CommentSection from './CommentSection';
import styles from './RecordDetail.module.css';

function RecordDetail() {
  const { recordId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, userId } = useAuth();
  const [recordDetail, setRecordDetail] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthor, setIsAuthor] = useState(false);

  // Refs for left and right columns
  const leftColumnRef = useRef(null);
  const rightColumnRef = useRef(null);

  useEffect(() => {
    const fetchRecordDetail = async () => {
      try {
        console.log('Fetching record detail...');
        const data = await getPostDetail(recordId);
        console.log('Record detail fetched:', data);
        setRecordDetail(data);

        // 작성자 확인
        if (isAuthenticated && data.userId === userId) {
          console.log('User is the author:', { isAuthenticated, userId, dataUserId: data.userId });
          setIsAuthor(true);
        } else {
          console.log('User is NOT the author:', { isAuthenticated, userId, dataUserId: data.userId });
          setIsAuthor(false);
        }

        // 이미지 로딩 로직 추가
        if (data.images && data.images.length > 0) {
          console.log('Loading images...');
          const imageUrls = await Promise.all(
            data.images.map(async (imagePath) => {
              try {
                return await loadImage(imagePath);
              } catch (error) {
                console.error(`Failed to load image ${imagePath}:`, error);
                return null;
              }
            })
          );
          console.log('Images loaded:', imageUrls);
          setLoadedImages(imageUrls.filter((url) => url !== null));
        }
      } catch (err) {
        console.error('Error fetching record detail:', err);
        setError('Failed to load record details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecordDetail();
  }, [recordId, isAuthenticated, userId]);

  useEffect(() => {
    // Adjust left column height to match right column
    if (rightColumnRef.current && leftColumnRef.current) {
      leftColumnRef.current.style.height = `${rightColumnRef.current.offsetHeight}px`;
    }
  }, [recordDetail]);

  const handleEdit = () => {
    navigate(`/records/edit/${recordId}`);
  };

  const handleDelete = async () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      try {
        console.log('Deleting record...');
        await deletePost(recordId);
        alert('게시물이 삭제되었습니다.');
        navigate('/records'); // 삭제 후 목록 페이지로 이동
      } catch (err) {
        console.error('Failed to delete record:', err);
        alert('삭제에 실패했습니다. 다시 시도해주세요.');
      }
    }
  };
  

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;

  const { content, course } = recordDetail || {};

  console.log('Rendering RecordDetail component:', { recordDetail, isAuthor });

  return (
    <>
      <CustomNavbar />
      {recordDetail && (
        <Container fluid className={styles.recordDetailContainer}>
          <Row className="gx-0 w-100 m-0">
            {/* Left Column: Course Preview */}
            <Col xs={3} className={styles.leftColumn} ref={leftColumnRef}>
              <div className={styles.coursePreview}>
                <img
                  src={course.imageUrl}
                  alt={course.title}
                  className={styles.courseImage}
                />
                <h3>{course.title}</h3>
                <p>⭐ 평균 별점: {course.starAvg.toFixed(1)}</p>
                <div className={styles.courseSpotsContainer}>
                  <CourseSpots spots={course.spots || []} />
                </div>
                <Button
                  variant="primary"
                  className={styles.goToCourseButton}
                  onClick={() => navigate(`/courses/${course.courseId}`)}
                >
                  코스 보러 가기
                </Button>
              </div>
            </Col>

            {/* Right Column: Record Content */}
            <Col xs={9} className={styles.rightColumn} ref={rightColumnRef}>
              <div className={styles.recordContent}>
                {isAuthor && (
                  <div className={styles.actionButtons}>
                    <Button
                      variant="outline-danger"
                      className={styles.deleteButton}
                      onClick={handleDelete}
                    >
                      🗑️
                    </Button>
                  </div>
                )}
                <h1 className={styles.title}>{recordDetail.title}</h1>
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{ __html: content }}
                />

                {/* 이미지 갤러리 */}
                {loadedImages.length > 0 && (
                  <div className={styles.imageGallery}>
                    {loadedImages.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl}
                        alt={`Record Image ${index + 1}`}
                        className={styles.recordImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/path/to/default-image.jpg'; // 기본 이미지 경로
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>

              {/* 댓글 섹션 */}
              <CommentSection recordId={recordId} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default RecordDetail;
