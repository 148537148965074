import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../../../components/navbar/CustomNavbar';
import PaginationUtil from '../../../components/pagination/PaginationUtil';
import CardItem from '../../../components/card/CardItem';
import { fetchUserRecords } from '../../../services/api/record/RecordListService';
import './MyRecordList.css';
import axiosInstance from '../../../services/api/axiosInstance';

const processImage = async (imagePath) => {
  try {
    if (imagePath.startsWith('https://lh3.googleusercontent.com') || imagePath.startsWith('https://lh3.google.com')) {
      return imagePath; // Google 이미지는 그대로 반환
    }
    const response = await axiosInstance.get(imagePath, { responseType: 'blob' });
    return URL.createObjectURL(response.data); // Blob URL로 변환
  } catch (error) {
    console.error(`❌ Failed to load image: ${imagePath}`, error);
    return 'https://via.placeholder.com/300'; // 기본 이미지 반환
  }
};

function MyRecordList() {
  const [userLogs, setUserLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const loadUserRecords = async () => {
      setLoading(true);
      setError(null);

      try {
        const { records, totalItems } = await fetchUserRecords(currentPage - 1, itemsPerPage);

        // 이미지 처리 추가
        const recordsWithImages = await Promise.all(
          records.map(async (record) => {
            const imageUrl = record.imageUrl
              ? await processImage(record.imageUrl)
              : 'https://via.placeholder.com/300';
            return { ...record, imageUrl };
          })
        );

        setUserLogs(recordsWithImages);
        setTotalItems(totalItems || 0);
      } catch (error) {
        console.error('❌ Error fetching user records:', error);
        setError('Failed to load user records.');
      } finally {
        setLoading(false);
      }
    };

    loadUserRecords();
  }, [currentPage]);

  const handleCardClick = (postId) => {
    navigate(`/records/${postId}`);
  };

  return (
    <>
      <div className="user-records-container">
        <h2 className="user-records-title">내 여행 기록</h2>
        <Button
          variant="primary"
          className="write-record-button"
          onClick={() => navigate('/records/create')}
        >
          기록 작성하러 가기
        </Button>
        <Container>
          {loading && (
            <div className="loading-container">
              <Spinner animation="border" />
              <p>Loading user records...</p>
            </div>
          )}
          {error && !loading && <Alert variant="danger">{error}</Alert>}
          {!loading && !error && userLogs.length === 0 && (
            <p className="no-user-records">아직 작성한 기록이 없습니다.</p>
          )}
          <Row>
            {!loading &&
              !error &&
              userLogs.map((log) => (
                <Col key={log.postId} lg={3} md={4} sm={6} xs={12} className="mb-4">
                  <CardItem
                    title={log.title}
                    description={log.description || ''}
                    metaRight={`💬 ${log.commentCount || 0}`}
                    imageUrl={log.imageUrl}
                    showBookmark={false}
                    onClick={() => handleCardClick(log.postId)}
                  />
                </Col>
              ))}
          </Row>

          <div className="pagination-container">
            <PaginationUtil
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default MyRecordList;
