import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // 부트스트랩 스타일 추가
import styles from './Home.module.css';

function Home() {
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate 초기화

  const handleStart = () => {
    navigate('/login'); // 로그인 페이지로 이동
  };

  return (
    <div className={styles.container}>
      {/* 로고 */}
      <div className={styles.logo}>SOLLAIM</div>

      {/* 이미지 슬라이드 */}
      <Carousel className={styles.carousel}>
        {/* 첫 번째 슬라이드 */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcSkbmzAN3FW3-UpdAIXZbWmX2hcLXugY1qsI--q2GmNQOxH9pV6DMk2avMDhPvfp872Ec8CK554ERtLyNJdv5_iNFvmzL0F-a9hNY30wQ"
            alt="서울 남산타워"
          />
          <Carousel.Caption>
            <h3>서울 남산타워</h3>
            <p>
              1980년에 문을 연 이 상징적인 타워는 도심의 탁 트인 전망을 자랑하며 회전식
              레스토랑을 이용할 수 있습니다.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        {/* 두 번째 슬라이드 */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://lh5.googleusercontent.com/p/AF1QipPhbuzh9IYh7NKPRHNGP8Xpiz9YMBZ2EiJsEUuM=w540-h312-n-k-no"
            alt="부산 엑스 더 스카이"
          />
          <Carousel.Caption>
            <h3>부산 엑스 더 스카이</h3>
            <p>
              대한 8경에 빛나는 드넓은 ⁩⁦해운대⁩⁦의 오션뷰와 ⁩⁦부산⁩⁦의 화려한 시티뷰를 볼 수 있습니다.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        {/* 세 번째 슬라이드 */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://lh3.googleusercontent.com/p/AF1QipMCpT5yl7rTUhhTkDbgBvtorD2SKY7U76XzjCwJ=s294-w294-h220-k-no"
            alt="제주도 섭지코지"
          />
          <Carousel.Caption>
            <h3>제주도 섭지코지</h3>
            <p>
              등대, 레스토랑, 상점, 경치 좋은 전망, 산책로를 갖추고 있는 조용한 해변 지역입니다.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* 메인 콘텐츠 */}
      <div className={styles.content}>
        <h1 className={styles.mainHeading}>
          기존에 경험하지 못한 <br />
          새로운 여행 플래너
        </h1>
        <p className={styles.subHeading}>
          고민만 하던 여행 계획을 <span className={styles.highlight}>SOLLAIM</span>을 통해
          몇 분 만에 스케줄링 해보세요.
        </p>
        <button onClick={handleStart} className={styles.startButton}>
          SOLLAIM 시작하기
        </button>
      </div>
    </div>
  );
}

export default Home;