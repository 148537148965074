// src/utils/cookieUtils.js

// 쿠키 설정 함수
export const setCookie = (name, value) => {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30일 후 만료
    document.cookie = `${name}=${value}; path=/; expires=${date.toUTCString()}; SameSite=Strict; Secure;`;
  };
  
  
// 쿠키에서 값을 가져오는 함수
export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    console.log('전체 쿠키:', document.cookie); // 디버깅용 로그 추가
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) {
        const cookieValue = c.substring(nameEQ.length, c.length);
        console.log(`${name} 쿠키 값:`, cookieValue); // 디버깅용 로그 추가
        return cookieValue;
      }
    }
    return null;
};
  
// 쿠키에서 값을 삭제하는 함수
export const deleteCookie = (name) => {
    const date = new Date();
    date.setTime(date.getTime() - 1);  // 현재 시간보다 과거의 시간 설정
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/;`;
  };
  
  
  // 리프레시 토큰을 쿠키에 설정하는 함수
export const setRefreshTokenInCookie = (refreshToken) => {
  const date = new Date();
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000); // 7일 후 만료
  document.cookie = `refreshToken=${refreshToken}; path=/; expires=${date.toUTCString()}; SameSite=Strict; Secure;`;
};

// 토큰 만료 확인 함수
export const isTokenExpired = (token) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000); // 현재 시간 (초 단위)
    return payload.exp < currentTime; // 토큰 만료 시간 비교
  } catch (error) {
    console.error('토큰 만료 확인 중 오류:', error);
    return true; // 토큰이 잘못된 경우 만료된 것으로 처리
  }
};