// src/services/api/deleteUserService.js

import axiosInstance from '../axiosInstance';

/**
 * 회원 탈퇴 API 호출
 * @returns {Promise<Object>} API 응답 데이터
 */
const deleteUser = async () => {
  try {
    const response = await axiosInstance.delete('/api/v1/user');
    console.log('✅ 회원 탈퇴 성공:', response.data);
    return response.data; // { retCode, retMessage, data: null }
  } catch (error) {
    console.error('❌ 회원 탈퇴 실패:', error.response || error);
    // 에러 응답을 그대로 호출자에게 전달
    throw error.response?.data || error;
  }
};

export default deleteUser;
