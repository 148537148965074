import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCookie, deleteCookie, isTokenExpired } from '../utils/cookieUtils';
import getUserInfo from '../services/api/auth/UserInfoService'; // UserInfoService 추가

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // 초기값 null
  const [userId, setUserId] = useState(null); // userId 상태 추가

  useEffect(() => {
    const initializeAuth = async () => {
      const accessToken = getCookie('accessToken');
      console.log('AuthContext 마운트 시 accessToken:', accessToken);

      if (accessToken && !isTokenExpired(accessToken)) {
        try {
          const userInfo = await getUserInfo();
          console.log('유저 정보:', userInfo);
          setIsAuthenticated(true);
          setUserId(userInfo.userId); // userId 설정
        } catch (error) {
          console.error('유저 정보 가져오기 실패:', error);
          handleLogout();
        }
      } else {
        handleLogout();
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    console.log('인증 상태 변경됨:', { isAuthenticated, userId });
  }, [isAuthenticated, userId]);

  const login = () => {
    console.log('login 함수 호출됨');
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    console.log('logout 함수 호출됨');
    deleteCookie('accessToken');
    deleteCookie('refreshToken');
    setIsAuthenticated(false);
    setUserId(null); // 로그아웃 시 userId 초기화
  };

  if (isAuthenticated === null) {
    // 로딩 중 표시
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, login, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
