import React from 'react';
import { Form } from 'react-bootstrap';

function GenderRadioGroup({ name, value, onChange }) {
  return (
    <Form.Group className="mb-3">
      <Form.Label>성별</Form.Label>
      <div>
        {['남', '여', '기타'].map((gender) => (
          <Form.Check
            inline
            label={gender}
            name={name}
            type="radio"
            id={gender}
            value={gender}
            onChange={onChange}
            checked={value === gender}
            key={gender}
          />
        ))}
      </div>
    </Form.Group>
  );
}

export default GenderRadioGroup;
