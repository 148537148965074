import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../../components/navbar/CustomNavbar';
import PaginationUtil from '../../components/pagination/PaginationUtil';
import CardItem from '../../components/card/CardItem';
import { fetchTravelRecords, loadImage } from '../../services/api/record/RecordListService';
import './RecordList.css';

function RecordList() {
  const [travelLogs, setTravelLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const loadTravelRecords = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const { records, totalItems } = await fetchTravelRecords(currentPage - 1, itemsPerPage);
  
        if (Array.isArray(records)) {
          const updatedLogs = await Promise.all(
            records.map(async (log) => {
              if (log.imageUrl) {
                try {
                  const imageUrl = await loadImage(log.imageUrl);
                  return { ...log, imageUrl };
                } catch (error) {
                  console.error(`Failed to load image for post ${log.postId}:`, error);
                  return { ...log, imageUrl: 'https://lh3.google.com/u/0/d/1jpwK2Ekz4IFGYlBYlCE0tyWd4MRIscrI=w958-h891-iv1' }; // 기본 이미지
                }
              }
              return { ...log, imageUrl: 'https://lh3.google.com/u/0/d/1jpwK2Ekz4IFGYlBYlCE0tyWd4MRIscrI=w958-h891-iv1' }; // 기본 이미지
            })
          );
  
          setTravelLogs(updatedLogs);
          setTotalItems(totalItems || 0);
        } else {
          throw new Error('Invalid records format');
        }
      } catch (error) {
        console.error('Failed to fetch travel records:', error);
        setError('Failed to load travel records.');
      } finally {
        setLoading(false);
      }
    };
  
    loadTravelRecords();
  }, [currentPage]);
  

  const handleCardClick = (postId) => {
    navigate(`/records/${postId}`);
  };

  return (
    <>
      <CustomNavbar />
      <div className="travel-records-container">
        <h2 className="travel-records-title">여행 기록</h2>
        <Button
          variant="primary"
          className="write-record-button"
          onClick={() => navigate('/records/create')}
        >
          여행 기록 작성하러 가기
        </Button>
        <Container>
          {loading && (
            <div className="loading-container">
              <Spinner animation="border" />
              <p>Loading travel records...</p>
            </div>
          )}
          {error && !loading && <Alert variant="danger">{error}</Alert>}
          {!loading && !error && travelLogs.length === 0 && (
            <p className="no-travel-records">여행 기록이 없습니다.</p>
          )}
          <Row>
            {!loading &&
              !error &&
              travelLogs.map((log) => (
                <Col key={log.postId} lg={3} md={4} sm={6} xs={12} className="mb-4">
                  <CardItem
                    title={log.title}
                    description={log.description || ''} // 추가 설명
                    metaRight={`💬 ${log.commentCount || 0}`}
                    imageUrl={log.imageUrl} // API에서 받은 이미지 URL 직접 사용
                    showBookmark={false}
                    isBookmarked={log.isBookmarked || false}
                    onClick={() => handleCardClick(log.postId)}
                  />
                </Col>
              ))}
          </Row>

          <div className="pagination-container">
            <PaginationUtil
              totalPages={Math.ceil(totalItems / itemsPerPage)}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </Container>
      </div>
    </>
  );
}

export default RecordList;
