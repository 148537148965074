import React, { useState, useEffect } from 'react';
import RecommendedSpots from './RecommendedSpots';
import Planner from './Planner';
import Chatbot from './Chatbot';
import { fetchSpotDetails, fetchPlannerSpotDetails } from '../../../../services/api/course/CourseCreateService';
import { Spinner, Button } from 'react-bootstrap';

function LeftColumn({ destination, duration, onAddPlace, botRecommendations }) {
  const [mode, setMode] = useState('recommend');
  const [recommendedSpots, setRecommendedSpots] = useState([]);
  const [plannerData, setPlannerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [storedBotRecommendations, setStoredBotRecommendations] = useState([]);

  useEffect(() => {
    // Clear existing stored data when destination changes
    localStorage.removeItem('recommendedSpots');
    localStorage.removeItem('plannerData');
    localStorage.removeItem('botRecommendations');

    fetchRecommendedSpotsWithDetails();
    setPlannerData([]);
    console.log('🟢 LeftColumn received botRecommendations:', botRecommendations);
  }, [destination, duration]);

  useEffect(() => {
    if (botRecommendations && botRecommendations.length > 0) {
      // Check if botRecommendations exists and has a 'schedule' property
      const formattedBotRecommendations = botRecommendations.map((rec) => {
        // Ensure schedule exists and is an array before mapping
        const schedule = rec.schedule && Array.isArray(rec.schedule) 
          ? rec.schedule.map((item) => ({
              name: item.name,
              address: item.address,
              latitude: item.latitude,
              longitude: item.longitude,
              id: item.id,
            }))
          : [];
  
        return {
          day: 1,
          schedule: schedule,
          table: rec.table // Preserve table information at the top level
        };
      });
      
      setStoredBotRecommendations(formattedBotRecommendations);
      localStorage.setItem('botRecommendations', JSON.stringify(formattedBotRecommendations));
    }
    console.log('🟢 LeftColumn storedBotRecommendations:', storedBotRecommendations);
  }, [botRecommendations]);

  const fetchRecommendedSpotsWithDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/v1/ai/recommend/spot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location: destination }),
      });
      const data = await response.json();

      if (data.retCode === '00') {
        const spots = data.data.recommendations;
        const spotsWithDetails = await Promise.all(
          spots.map(async (spot) => {
            const detailResponse = await fetchSpotDetails(spot.id);
            return detailResponse.success ? { ...spot, ...detailResponse.data } : spot;
          })
        );

        localStorage.setItem('recommendedSpots', JSON.stringify(spotsWithDetails));
        setRecommendedSpots(spotsWithDetails);
      }
    } catch (error) {
      console.error('추천 장소 API 호출 중 오류 발생:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlannerDataWithDetails = async () => {
    setCreating(true);
    try {
      const response = await fetch('/api/v1/ai/planner', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ location: destination, days: duration }),
      });
      const data = await response.json();

      if (data.retCode === '00') {
        const itinerary = data.data.itinerary;

        // 일정 데이터를 상세 정보와 함께 업데이트
        const itineraryWithDetails = await Promise.all(
          itinerary.map(async (day) => ({
            ...day,
            schedule: await Promise.all(
              day.schedule.map(async (item) => {
                const detailResponse = await fetchPlannerSpotDetails(item.id);
                return detailResponse.success ? { ...item, ...detailResponse.data } : item;
              })
            ),
          }))
        );

        setPlannerData(itineraryWithDetails); // 상태 업데이트
        localStorage.setItem('plannerData', JSON.stringify(itineraryWithDetails)); // 로컬 스토리지 업데이트
      } else {
        console.error('일정 생성 실패:', data.retMessage);
      }
    } catch (error) {
      console.error('여행 일정 API 호출 중 오류 발생:', error);
    } finally {
      setCreating(false);
    }
  };

  const handleSwitchMode = async (newMode) => {
    if (newMode === 'planner' && !plannerData.length) {
      await fetchPlannerDataWithDetails(); // 일정 생성 API 호출 보장
    }
    setMode(newMode);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" />
        <p>추천 장소를 불러오는 중입니다... 잠시만 기다려주세요!</p>
      </div>
    );
  }

  return (
    <div className="left-column">
      <div className="mode-switch-buttons">
        <Button variant="primary" onClick={() => handleSwitchMode('recommend')}>
          추천 장소
        </Button>
        <Button variant="secondary" onClick={() => handleSwitchMode('planner')}>
          일정 플래너
        </Button>
        <Button variant="success" onClick={() => handleSwitchMode('chatbot')}>
          챗봇 추천
        </Button>
      </div>
      {creating && (
        <div className="creating-container">
          <Spinner animation="grow" />
          <p>여행 일정을 생성 중입니다... 잠시만 기다려주세요!</p>
        </div>
      )}
      {mode === 'recommend' && (
        <RecommendedSpots
          recommendedSpots={recommendedSpots}
          onSwitchToPlanner={() => handleSwitchMode('planner')}
        />
      )}
      {mode === 'planner' && (
        <Planner
          plannerData={plannerData}
          duration={duration}
          botResponses={storedBotRecommendations}
          onSwitchToRecommended={() => handleSwitchMode('recommend')}
          onAddPlace={onAddPlace}
        />
      )}
      {mode === 'chatbot' && (
        <Chatbot
          botRecommendations={storedBotRecommendations || []}
          onAddPlace={onAddPlace}
        />
      )}
    </div>
  );
}

export default LeftColumn;