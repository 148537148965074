import axiosInstance from '../axiosInstance';

/**
 * 북마크 생성
 * @param {number} courseId - 북마크할 코스의 ID
 * @returns {Promise<object>} - 북마크 생성 응답 데이터
 */
const createBookmark = async (courseId) => {
  try {
    const response = await axiosInstance.post(`/api/v1/user/bookmark/${courseId}`);
    console.log('📥 Bookmark Created:', response.data);
    return response.data;
  } catch (error) {
    console.error('❌ Error creating bookmark:', error);
    throw error;
  }
};

/**
 * 북마크 삭제
 * @param {number} bookmarkId - 삭제할 북마크의 코스 ID
 * @returns {Promise<object>} - 북마크 삭제 응답 데이터
 */
const deleteBookmark = async (bookmarkId) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/user/bookmark/${bookmarkId}`);
    console.log('📥 Bookmark Deleted:', response.data);
    return response.data;
  } catch (error) {
    console.error('❌ Error deleting bookmark:', error);
    throw error;
  }
};

export { createBookmark, deleteBookmark };
// 이미지를 처리하는 메서드 (Google 이미지와 일반 이미지 분리)
/**
 * 이미지를 처리하는 메서드 (Google 이미지와 일반 이미지 분리)
 * @param {string} imagePath - 이미지 경로
 * @returns {Promise<string>} - 처리된 이미지 URL
 */
export const processImage = async (imagePath) => {
  try {
    // Google 이미지는 Blob 변환 없이 URL 그대로 반환
    if (imagePath.startsWith('https://lh3.googleusercontent.com') || imagePath.startsWith('https://lh3.google.com')) {
      return imagePath;
    }

    // 일반 이미지는 Blob으로 변환
    const response = await axiosInstance.get(imagePath, {
      responseType: 'blob', // 이미지를 Blob으로 받음
    });
    return URL.createObjectURL(response.data); // Blob URL 생성
  } catch (error) {
    console.error(`❌ Failed to load image: ${imagePath}`, error);
    return 'https://via.placeholder.com/300'; // 기본 이미지 반환
  }
};

const fetchUserBookmarks = async (page = 0, size = 12) => {
  try {
    const response = await axiosInstance.get('/api/v1/user/bookmark', {
      params: { page, size },
    });

    // Log response for debugging
    console.log('📥 API Response:', response);

    // Validate retCode
    const retCode = response?.data?.retCode;
    if (retCode !== '00') {
      throw new Error(response?.data?.retMessage || 'Unexpected response code');
    }

    // Extract bookmarks and totalItems
    const responseData = response.data.data;
    const bookmarks = responseData.data.map((bookmark) => ({
      bookmarkId: bookmark.bookmarkId,
      courseId : bookmark.courseId,
      courseImageUrl: bookmark.courseImageUrl || 'https://via.placeholder.com/300',
      courseTitle: bookmark.courseTitle || '제목 없음',
      courseStarAvg: bookmark.courseStarAvg || 0,
    }));

    return { bookmarks, totalItems: responseData.totalItems };
  } catch (error) {
    console.error('❌ Error fetching user bookmarks:', error.response || error.message || error);
    throw error;
  }
};

export { fetchUserBookmarks };
